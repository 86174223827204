import { LoaderFunctionArgs } from "react-router-dom";
import { store } from "../../store";
import { resetDocument } from "../reducer/document-reducer";
import { getAllDocument } from "../../actions/document";

export const documentsLoader = async (args: LoaderFunctionArgs) => {
  store.dispatch(resetDocument());

  return await getAllDocument();
};
