import { Form } from "antd";
import { InputText } from "../../../components/input";
import { CustomButton } from "../../../components/button";
import {
  useAppDispatch,
  useAppSelector,
  useAppTranslation,
} from "../../../constants/hooks";
import { RootState } from "../../../store";
import { useEffect } from "react";
import {
  useHandleAdminNameChange,
  useHandleAdminPasswordChange,
} from "../../../constants/utils";
import {
  useAppNavigate,
  useAppState,
} from "../../../constants/hooks/global-hooks";
import { modifyProfile } from "../../../actions/profile";

export const ProfilePageForm = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();
  const navigate = useAppNavigate();
  const { authenticatedUser } = useAppSelector(
    (state: RootState) => state.session
  );
  const { email, name, password, errorMessage } = useAppSelector(
    (state: RootState) => state.user
  );
  const [state, setState] = useAppState({
    passwordCheck: "",
    clicked: false,
  });
  useEffect(() => {
    if (authenticatedUser) {
      form.setFieldsValue({
        email: authenticatedUser.email,
        name: authenticatedUser.name,
      });
    }
  }, [authenticatedUser, form]);

  const handleModifyProfile = async () => {
    await modifyProfile();
    setState((prevState) => ({ ...prevState, clicked: true }));
  };

  const handleModifyPasswordCheck = (value: string) => {
    setState((prevState) => ({ ...prevState, passwordCheck: value }));
  };

  useEffect(() => {
    if (state.clicked && errorMessage === "") {
      navigate("/funds");
    }
    setState((prevState) => ({ ...prevState, clicked: false }));
  }, [state.clicked, errorMessage, navigate, setState]);

  return (
    <Form
      form={form}
      name="profile-page-form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 24 }}
    >
      <Form.Item
        label={t("profile-page-form-email-title")}
        name="email"
        rules={[
          {
            required: true,
            message: t("admin-page-create-user-modal-input-error-message"),
          },
        ]}
      >
        <InputText disabled={true} value={email} onChange={() => {}} />
      </Form.Item>
      <Form.Item
        label={t("profile-page-form-name-title")}
        name="name"
        rules={[
          {
            required: true,
            message: t("admin-page-create-user-modal-input-error-message"),
          },
        ]}
      >
        <InputText value={name} onChange={useHandleAdminNameChange(dispatch)} />
      </Form.Item>
      <Form.Item
        label={t("profile-page-form-password-title")}
        name="password"
        rules={[
          {
            required: true,
            message: t("admin-page-create-user-modal-input-error-message"),
          },
        ]}
      >
        <InputText
          isPassword={true}
          value={password}
          onChange={useHandleAdminPasswordChange(dispatch)}
        />
      </Form.Item>
      <Form.Item
        label={t("profile-page-form-password-confirm-title")}
        name="passwordConfirm"
        rules={[
          {
            required: true,
            message: t("admin-page-create-user-modal-input-error-message"),
          },
        ]}
      >
        <InputText
          isPassword={true}
          value={state.passwordCheck}
          onChange={handleModifyPasswordCheck}
        />
      </Form.Item>
      <div className="admin-form-button-container">
        <CustomButton
          disabled={
            password !== state.passwordCheck &&
            state.passwordCheck !== "" &&
            name !== ""
          }
          className={`profile-page-submit ${
            password !== state.passwordCheck &&
            state.passwordCheck !== "" &&
            name !== ""
              ? "disabled"
              : ""
          }`}
          onClick={handleModifyProfile}
          title={t("profile-page-form-submit-button-title")}
        />
      </div>
    </Form>
  );
};
