import { useCallback } from "react";
import { setMessageSearchText } from "../reducer/message-reducer";
import { getAllMessage } from "../../actions/message";

export const useHandleMessageSearchChange = (dispatch: Function) => {
  const handleSearchChange = useCallback(
    (value: string) => {
      dispatch(setMessageSearchText(value));
    },
    [dispatch]
  );
  return handleSearchChange;
};

export const useHandleGetAllMessage = () => {
  const getMessages = useCallback(async () => {
    await getAllMessage();
  }, []);
  return getMessages;
};
