import { Expose } from "class-transformer";
import { IsDefined, IsNumber } from "class-validator";
export class ExchangeRate {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  date!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  exchangeRate!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  netPrice!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  fundId!: number;
}
