import { CustomErrorType } from "../../constants/action-types";
import { SuccessDTO } from "../../constants/dto/success";
import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { BASE_METHODS } from "../../constants/enum";
import {
  removeAdminFromAdmins,
  setAdminActionStart,
  setAdminErrorMessage,
} from "../../constants/reducer/user-reducer";
import { timeoutError } from "../../constants/reducer/base-reducer";

type DeleteAdminProps = {
  id: number;
};
let body: SuccessDTO;
export const deleteAdmin = async (props: DeleteAdminProps) => {
  store.dispatch(setAdminActionStart(true));
  const { id } = props;

  await createCall(
    `cms/users/${id}`,
    BASE_METHODS.DELETE,
    "application/json",
    undefined,
    undefined
  )
    .then(async (response) => {
      try {
        body = plainToClass(SuccessDTO, JSON.parse(await response.text()));
      } catch (error) {
        console.log(error);
        return;
      }
      if ((await validate(body)).length > 0) {
        store.dispatch(setAdminErrorMessage("delete-admin-invalid-response"));
        return;
      }

      store.dispatch(removeAdminFromAdmins(id));
    })
    .catch((error) => {
      const errorResponeData: CustomErrorType = error;
      if (errorResponeData) {
        let errorMessage = "";
        switch (errorResponeData.code) {
          case 1001:
            errorMessage = "first-login-admin-not-found-error-message";
            break;
          case 1005:
            errorMessage = "delete-admin-cannot-delete-yourself";
            break;
          default:
            break;
        }
        store.dispatch(setAdminErrorMessage(errorMessage));
        return;
      }
      store.dispatch(setAdminActionStart(false));
      store.dispatch(timeoutError());
    });
};
