import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { store } from "../../store";

import { createCall } from "../base";

import { BASE_METHODS } from "../../constants/enum";
import { logout } from "../../constants/reducer/session-reducer";
import { SuccessDTO } from "../../constants/dto/success";
import {
  loginOk,
  setAlertMessage,
} from "../../constants/reducer/login-reducer";

let body: SuccessDTO;

export const logoutAction = async () => {
  await createCall(
    "cms/users/logout",
    BASE_METHODS.POST,
    "application/json",
    undefined,
    undefined
  )
    .then(async (response) => {
      try {
        body = plainToClass(SuccessDTO, JSON.parse(await response.text()));
      } catch (error) {
        console.log(error);
        return;
      }
      if ((await validate(body)).length > 0) {
        store.dispatch(setAlertMessage("logout-invalid-response-error"));
        return;
      }

      store.dispatch(loginOk());
      store.dispatch(logout());
    })
    .catch((error) => {
      console.log(error);
      store.dispatch(setAlertMessage("logout-invalid-response-error"));
      return;
    });
};
