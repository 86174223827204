import { SnippetsOutlined } from "@ant-design/icons";
import { Document } from "../../../constants/entity";
import {
  useAppDispatch,
  useAppMemo,
  useAppTranslation,
} from "../../../constants/hooks";
import { setDocument } from "../../../constants/reducer/document-reducer";
import { deleteDocument } from "../../../actions/document/delete-document";
import { DeleteIcon, EditIcon } from "../../../components/icon";
import { CustomPopConfirm } from "../../../components/popconfirm";

type DocumentColumnsProps = {
  handleModalVisible: () => void;
};

export const DocumentColumns = ({
  handleModalVisible,
}: DocumentColumnsProps) => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();

  const handleModify = (record: Document) => {
    dispatch(setDocument({ document: record }));
    handleModalVisible();
  };

  const handleDelete = async (id: number) => {
    await deleteDocument({ id });
  };

  return useAppMemo(() => {
    return [
      {
        title: t("documents-page-column-id-title"),
        key: t("documents-page-column-id-title"),
        dataIndex: "id",
        width: "10%",
      },
      {
        title: t("documents-page-column-name-title"),
        key: t("documents-page-column-name-title"),
        dataIndex: "title",
        width: "30%",
      },
      {
        title: t("documents-page-column-url-title"),
        key: t("documents-page-column-url-title"),
        render: (text: string, record: Document): JSX.Element => {
          return <span>{record.file?.filename}</span>;
        },
        width: "20%",
      },
      {
        title: t("documents-page-column-type-title"),
        key: t("documents-page-column-type-title"),
        render: (text: string, record: Document): JSX.Element => {
          return (
            <span>
              {record.type === 1
                ? t("conservative_title")
                : record.type === 2
                ? t("balanced_title")
                : t("dynamic_title")}
            </span>
          );
        },
        filters: [
          { text: t("conservative_title"), value: 1 },
          { text: t("balanced_title"), value: 2 },
          { text: t("dynamic_title"), value: 3 },
        ],
        onFilter: (value: number, record: Document) => record.type === value,
        width: "10%",
      },

      {
        title: t("documents-page-column-language-title"),
        key: t("documents-page-column-language-title"),
        dataIndex: "language",
        render: (text: string, record: Document): JSX.Element => {
          return <span>{record.language}</span>;
        },

        filters: [
          { text: t("langugage_en_short_button"), value: "en" },
          { text: t("langugage_hu_short_button"), value: "hu" },
          { text: t("langugage_hr_short_button"), value: "hr" },
          { text: t("langugage_mne_short_button"), value: "mne" },
          { text: t("langugage_sl_short_button"), value: "sl" },
          { text: t("langugage_bg_short_button"), value: "bg" },
        ],
        onFilter: (value: string, record: Document) =>
          record.language === value,
        width: "20%",
      },
      {
        title: t("fund-page-exchange-rate-column-header-modify-title"),
        key: t("fund-page-exchange-rate-column-header-modify-title"),
        render: (text: string, record: Document) => {
          return <EditIcon onClick={() => handleModify(record)} />;
        },
        width: "5%",
      },
      {
        title: t("fund-page-exchange-rate-column-delete-title"),
        key: t("fund-page-exchange-rate-column-delete-title"),
        render: (text: string, record: Document) => {
          return (
            <CustomPopConfirm
              title={t("fund-page-exchange-rate-column-delete-popup-confirm")}
              okText={t("yes")}
              cancelText={t("no")}
              onConfirm={() => handleDelete(record.id)}
            >
              <button className="table-delete-button">
                <DeleteIcon />
              </button>
            </CustomPopConfirm>
          );
        },
        width: "5%",
      },
    ];
  }, []);
};
