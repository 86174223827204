import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { ExchangeRate } from "../../entity";

export class CreateExchangeRateDTO {
  @Expose()
  @ValidateNested()
  @Type(() => ExchangeRate)
  exchangeRate!: ExchangeRate;
}
