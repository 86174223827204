import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";

import { BASE_METHODS } from "../../constants/enum";
import { timeoutError } from "../../constants/reducer/base-reducer";

import { GetAllMessageDTO } from "../../constants/dto/message";
import {
  setMessageActionStart,
  setMessageErrorMessage,
  setMessages,
} from "../../constants/reducer/message-reducer";

let body: GetAllMessageDTO;

export const getAllMessage = async () => {
  store.dispatch(setMessageActionStart(true));

  const { searchText } = store.getState().message;
  const queryParams = new URLSearchParams();
  if (searchText.trim() !== "")
    queryParams.append("searchText", searchText.trim());

  await createCall(
    "cms/message",
    BASE_METHODS.GET,
    "application/json",
    undefined,
    queryParams
  )
    .then(async (response) => {
      try {
        body = plainToClass(
          GetAllMessageDTO,
          JSON.parse(await response.text())
        );
      } catch (error) {
        console.log(error);
        return;
      }
      if ((await validate(body)).length > 0) {
        store.dispatch(
          setMessageErrorMessage("get-all-message-invalid-response")
        );
        return;
      }
      store.dispatch(setMessages({ messages: body.messages }));
    })
    .catch((error) => {
      store.dispatch(setMessageActionStart(false));
      store.dispatch(timeoutError());
    });
  return null;
};
