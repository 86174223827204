import { Modal, Space } from "antd";
import { useAppTranslation } from "../../constants/hooks";
import { CustomButton } from "../button";
import { ExclamationCircleFilled } from "@ant-design/icons";

import "./modal.scss";

interface MessageModalProps {
  title: string;
  message: string;
  onOkClick?: () => void;
  modalVisible: boolean;
  onModalClose?: () => void;
  errorTitle?: boolean;
}

export const MessageModal: React.FC<MessageModalProps> = ({
  title,
  message,
  onOkClick,
  modalVisible,
  onModalClose,
  errorTitle,
}) => {
  const { t } = useAppTranslation();

  return (
    <Modal
      zIndex={15000}
      className="message-modal"
      title={
        errorTitle ? (
          <Space className="title-text-h2">
            <ExclamationCircleFilled style={{ color: "red" }} />
            {t(title)}
          </Space>
        ) : (
          <div className="title-text-h2">{t(title)}</div>
        )
      }
      open={modalVisible}
      footer={
        <CustomButton
          title="OK"
          onClick={onOkClick ? onOkClick : onModalClose}
        />
      }
      onCancel={onOkClick ? onOkClick : onModalClose}
      centered
    >
      <div className="message-modal-error-description">{t(message)}</div>
    </Modal>
  );
};
