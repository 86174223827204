import { LoginDTO } from "../../constants/dto/login";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { store } from "../../store";

import { timeoutError } from "../../constants/reducer/base-reducer";
import { createCall } from "../base";
import {
  loginInvalidResponse,
  loginStarted,
  setAlertMessage,
  tooManyLoginAttempts,
} from "../../constants/reducer/login-reducer";
import { BASE_METHODS } from "../../constants/enum";
import { sessionLoginOk } from "../../constants/reducer/session-reducer";
import { CustomErrorType } from "../../constants/action-types";

let body: LoginDTO;

export const loginAction = async () => {
  store.dispatch(loginStarted());
  const { email, password } = store.getState().login;

  const params = {
    email: email,
    password: password,
  };

  await createCall(
    "cms/users/login",
    BASE_METHODS.POST,
    "application/json",
    params,
    undefined,
    true
  )
    .then(async (response) => {
      try {
        body = plainToClass(LoginDTO, JSON.parse(await response.text()));
      } catch (error) {
        console.log(error);
        return;
      }
      if ((await validate(body)).length > 0) {
        store.dispatch(loginInvalidResponse());
        return;
      }

      store.dispatch(
        sessionLoginOk({
          user: body.user,
          token: body.token.accessToken,
        })
      );
    })
    .catch((error) => {
      const errorResponeData: CustomErrorType = error;
      if (errorResponeData) {
        let errorMessage = "";
        switch (errorResponeData.code) {
          case 1002:
            errorMessage = "login-invalid-email-or-password";
            store.dispatch(setAlertMessage(errorMessage));
            break;
          case 1004:
            const date = new Date(errorResponeData.data);
            store.dispatch(tooManyLoginAttempts(date));
            break;
          default:
            break;
        }
        return;
      }
      store.dispatch(timeoutError());
      return;
    });
};
