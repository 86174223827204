import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { store } from "../../store";
import { createCall } from "../base";
import { BASE_METHODS } from "../../constants/enum";
import { timeoutError } from "../../constants/reducer/base-reducer";
import {
  setForgotPasswordActionStart,
  setForgotPasswordErrorMessage,
} from "../../constants/reducer/forgot-password-reducer";
import { SuccessDTO } from "../../constants/dto/success";

let body: SuccessDTO;

export const forgotPassword = async () => {
  store.dispatch(setForgotPasswordActionStart(true));
  const { email } = store.getState().forgotPassword;
  const bodyToSend = {
    email,
  };
  await createCall(
    "cms/users/forgot-password",
    BASE_METHODS.POST,
    "application/json",
    bodyToSend,
    undefined,
    true
  )
    .then(async (response) => {
      try {
        body = plainToClass(SuccessDTO, JSON.parse(await response.text()));
      } catch (error) {
        console.log(error);
        return;
      }
      if ((await validate(body)).length > 0) {
        store.dispatch(
          setForgotPasswordErrorMessage(
            "forgot-password-confirm-invalid-response"
          )
        );
        return;
      }

      store.dispatch(
        setForgotPasswordErrorMessage("forgot-password-confirm-message")
      );
      return response;
    })
    .catch((error) => {
      store.dispatch(setForgotPasswordActionStart(false));
      store.dispatch(timeoutError());
      return;
    });
};
