import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { Document } from "../../entity";

export class CreateDocumentDTO {
  @Expose()
  @ValidateNested()
  @Type(() => Document)
  document!: Document;
}
