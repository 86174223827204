import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";

import { BASE_METHODS } from "../../constants/enum";
import { GetAllAdminDTO } from "../../constants/dto/user";
import { timeoutError } from "../../constants/reducer/base-reducer";
import {
  setAdminActionStart,
  setAdminErrorMessage,
  setAdmins,
} from "../../constants/reducer/user-reducer";

let body: GetAllAdminDTO;

export const getAllAdmin = async () => {
  store.dispatch(setAdminActionStart(true));

  const { searchText } = store.getState().user;
  const queryParams = new URLSearchParams();
  if (searchText.trim() !== "")
    queryParams.append("searchText", searchText.trim());

  await createCall(
    "cms/users",
    BASE_METHODS.GET,
    "application/json",
    undefined,
    queryParams
  )
    .then(async (response) => {
      try {
        body = plainToClass(GetAllAdminDTO, JSON.parse(await response.text()));
      } catch (error) {
        console.log(error);
        return;
      }
      if ((await validate(body)).length > 0) {
        store.dispatch(setAdminErrorMessage("get-all-admin-invalid-response"));
        return;
      }
      store.dispatch(setAdmins({ users: body.users }));
    })
    .catch((error) => {
      store.dispatch(setAdminActionStart(false));
      store.dispatch(timeoutError());
    });
  return null;
};
