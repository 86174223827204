import React from "react";
import { Table } from "antd";
import { useAppMemo, useAppTranslation } from "../../constants/hooks";
import "./table.scss";
type TableProps = {
  columns: Array<any>;
  dataSource: Array<any>;
};

export const DataTable: React.FC<TableProps> = ({ columns, dataSource }) => {
  const { t } = useAppTranslation();
  return useAppMemo(() => {
    return (
      <div className="table-container">
        <Table
          loading={!dataSource}
          columns={columns}
          rowKey="id"
          dataSource={dataSource}
          locale={{ emptyText: t("table-is-empty-text") }}
          pagination={{ position: ["bottomCenter"] }}
        />
      </div>
    );
  }, [columns, dataSource]);
};
