import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginState } from "../state";

const initialState: LoginState = {
  alertMessage: "",
  email: "",
  password: "",
  loginStarted: null,
  remainingTime: null,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    changeEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    changePassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    loginStarted: (state) => {
      state.loginStarted = Date.now();
    },
    loginUnexpectedResponse: (state) => {
      state.loginStarted = null;
      state.remainingTime = null;
      state.alertMessage = "login-unexpected-response";
    },
    loginInvalidJson: (state) => {
      state.loginStarted = null;
      state.remainingTime = null;
      state.alertMessage = "login-invalid-json";
    },
    loginInvalidResponse: (state) => {
      state.loginStarted = null;
      state.remainingTime = null;
      state.alertMessage = "login-invalid-response";
    },
    loginTimeout: (state) => {
      state.loginStarted = null;
      state.remainingTime = null;
      state.alertMessage = "login-server-timeout-error";
    },
    loginUnauthorized: (state) => {
      state.loginStarted = null;
      state.remainingTime = null;
      state.alertMessage = "login-unauthorized-error";
    },
    loginOk: (state) => {
      state.loginStarted = null;
      state.remainingTime = null;
    },
    setAlertMessage: (state, action: PayloadAction<string>) => {
      state.alertMessage = action.payload;
      state.loginStarted = null;
    },
    tooManyLoginAttempts: (state, action: PayloadAction<Date | null>) => {
      state.loginStarted = null;
      state.remainingTime = action.payload;
      state.alertMessage = "login-too-many-login-attempts";
    },
  },
});

export const {
  changeEmail,
  changePassword,
  loginStarted,
  loginUnexpectedResponse,
  loginInvalidJson,
  loginInvalidResponse,
  loginTimeout,
  loginUnauthorized,
  loginOk,
  setAlertMessage,
  tooManyLoginAttempts,
} = loginSlice.actions;
