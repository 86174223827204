import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { BASE_METHODS } from "../../constants/enum";
import { CustomErrorType } from "../../constants/action-types";
import { timeoutError } from "../../constants/reducer/base-reducer";

import {
  setForgotPasswordActionStart,
  setForgotPasswordErrorMessage,
  setIsTokenValid,
  setToken,
} from "../../constants/reducer/forgot-password-reducer";
import { ForgotPasswordConfirmDTO } from "../../constants/dto/forgot-password";

let body: ForgotPasswordConfirmDTO;

export const forgotPasswordConfirm = async () => {
  store.dispatch(setForgotPasswordActionStart(true));
  const queryParams = new URLSearchParams();
  const getQueryParam = new URLSearchParams(window.location.search);
  const token = getQueryParam.get("token");
  if (token) queryParams.append("token", token);

  await createCall(
    "cms/users/forgot-password-confirm",
    BASE_METHODS.GET,
    "application/json",
    undefined,
    queryParams,
    true
  )
    .then(async (response) => {
      try {
        body = plainToClass(
          ForgotPasswordConfirmDTO,
          JSON.parse(await response.text())
        );
      } catch (error) {
        console.log(error);
        return;
      }
      if ((await validate(body)).length > 0) {
        store.dispatch(
          setForgotPasswordErrorMessage(
            "forgot-password-confirm-invalid-response-error-message"
          )
        );
        return;
      }

      store.dispatch(setToken(token));
      store.dispatch(setIsTokenValid(body));
    })
    .catch((error) => {
      const errorResponeData: CustomErrorType = error;
      if (errorResponeData) {
        let errorMessage = "";
        switch (errorResponeData.code) {
          case 4001:
            errorMessage = "forgot-password-confirm-token-expired";
            break;

          default:
            break;
        }
        store.dispatch(setForgotPasswordErrorMessage(errorMessage));
        return;
      }
      store.dispatch(setForgotPasswordActionStart(false));
      store.dispatch(timeoutError());
    });
  return null;
};
