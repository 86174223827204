import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Admin } from "../entity";
import { UserState } from "../state";

const initialState: UserState = {
  users: [],
  searchText: "",
  email: "",
  password: "",
  errorMessage: "",
  adminActionStart: false,
  name: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAdmins: (state, action: PayloadAction<{ users: Admin[] }>) => {
      state.users = action.payload.users;
      state.errorMessage = "";
      state.adminActionStart = false;
    },

    setAdminSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },

    setAdminEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },

    setAdminName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },

    setAdminPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },

    setAdminErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
      state.adminActionStart = false;
    },

    addAdminToAdmins: (state, action: PayloadAction<{ user: Admin }>) => {
      state.users = [action.payload.user, ...state.users];
      state.errorMessage = "";
      state.adminActionStart = false;
    },

    removeAdminFromAdmins: (state, action: PayloadAction<number>) => {
      state.users = state.users.filter((user) => user.id !== action.payload);
      state.errorMessage = "";
      state.adminActionStart = false;
    },

    resetAdmin: (state) => {
      state.email = "";
      state.password = "";
      state.searchText = "";
      state.name = "";
      state.errorMessage = "";
    },

    setAdminActionStart: (state, action: PayloadAction<boolean>) => {
      state.adminActionStart = action.payload;
    },
  },
});

export const {
  setAdmins,
  setAdminSearchText,
  setAdminEmail,
  setAdminPassword,
  setAdminErrorMessage,
  addAdminToAdmins,
  removeAdminFromAdmins,
  resetAdmin,
  setAdminActionStart,
  setAdminName,
} = userSlice.actions;
