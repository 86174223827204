import { SearchBar } from "../../components/searchbar";
import { DataTable } from "../../components/table";
import {
  useAppDispatch,
  useAppSelector,
  useAppTranslation,
  useDebounce,
} from "../../constants/hooks";
import {
  useHandleAdminAlertMessage,
  useHandleAdminSearchChange,
  useHandleGetAllUser,
} from "../../constants/utils";
import { RootState } from "../../store";
import { AdminColumns, CreateAdminForm } from "./components";
import { CustomButton } from "../../components/button";
import { useAppState } from "../../constants/hooks/global-hooks";
import { ROLE_ENUM } from "../../constants/enum";

import { CustomModal, MessageModal } from "../../components/modal";
import { createAdmin } from "../../actions/user";
import { useEffect } from "react";
import { resetAdmin } from "../../constants/reducer/user-reducer";

import "./admin-page.scss";

export const AdminPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();
  const { users, searchText, errorMessage } = useAppSelector(
    (state: RootState) => state.user
  );
  const { authenticatedUser } = useAppSelector(
    (state: RootState) => state.session
  );

  const [state, setState] = useAppState({
    modalVisible: false,
    clicked: false,
  });
  const getAllUser = useHandleGetAllUser();
  const resetErrorMessage = useHandleAdminAlertMessage(dispatch);
  const handleModalVisible = () => {
    dispatch(resetAdmin());
    setState((prevState) => ({
      ...prevState,
      modalVisible: !state.modalVisible,
    }));
  };

  const handleCreateUser = async () => {
    await createAdmin();
    setState((prevState) => ({ ...prevState, clicked: true }));
  };

  useEffect(() => {
    if (state.clicked && errorMessage === "") {
      setState((prevState) => ({
        ...prevState,
        modalVisible: false,
        clicked: false,
      }));
      dispatch(resetAdmin());
    }
    setState((prevState) => ({ ...prevState, clicked: false }));
  }, [state.clicked, errorMessage, setState, dispatch]);

  const debouncedUsers = useDebounce(
    getAllUser,
    searchText.trim() !== "" ? 500 : 0
  );

  useEffect(() => {
    debouncedUsers();
  }, [debouncedUsers]);

  return (
    <main className="page admin-page">
      <div className="title-text-h2">{t("admin-page-page-title")}</div>
      <div className="searchbar-container">
        <SearchBar
          value={searchText}
          onChange={useHandleAdminSearchChange(dispatch)}
          placeholder={t("admin-page-searchbar-placeholder")}
        />
        {authenticatedUser?.role.id === ROLE_ENUM.SUPER_ADMIN && (
          <CustomButton
            title={t("admin-page-create-admin-button-title")}
            onClick={handleModalVisible}
            className="searchbar-button"
          />
        )}
      </div>
      <DataTable dataSource={users} columns={Object.values(AdminColumns())} />
      <CustomModal
        title={"admin-page-create-user-modal-title"}
        onModalClose={handleModalVisible}
        onConfirm={handleCreateUser}
        modalVisible={state.modalVisible}
      >
        <CreateAdminForm />
      </CustomModal>
      <MessageModal
        modalVisible={errorMessage !== ""}
        title={t("timeout-error-title")}
        message={errorMessage}
        errorTitle={true}
        onOkClick={resetErrorMessage}
      />
    </main>
  );
};
