import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Document } from "../entity";
import { DocumentState } from "../state";
import { MediaFile } from "../entity/media-file-entity";

const initialState: DocumentState = {
  documents: [],
  searchText: "",
  documentActionStart: false,
  documentErrorMessage: "",
  title: "",
  language: "",
  file: null,
  id: -1,
  type: 1,
};

export const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    setDocumentActionStart: (state, action: PayloadAction<boolean>) => {
      state.documentActionStart = action.payload;
    },
    setDocumentErrorMessage: (state, action: PayloadAction<string>) => {
      state.documentErrorMessage = action.payload;
    },
    setDocuments: (state, action: PayloadAction<{ documents: Document[] }>) => {
      state.documentActionStart = false;
      state.documents = action.payload.documents;
      state.documentErrorMessage = "";
    },
    setDocument: (state, action: PayloadAction<{ document: Document }>) => {
      state.documentActionStart = false;
      state.documentErrorMessage = "";

      state.title = action.payload.document.title;
      state.language = action.payload.document.language;
      state.file = action.payload.document.file ?? null;
      state.id = action.payload.document.id;
      state.type = action.payload.document.type;
    },

    setDocumentSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setDocumentTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setDocumentLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setDocumentFile: (state, action: PayloadAction<MediaFile | null>) => {
      state.file = action.payload;
    },
    setDocumentType: (state, action: PayloadAction<number>) => {
      state.type = action.payload;
    },
    resetDocument: (state) => {
      state.searchText = "";
      state.documentActionStart = false;
      state.documentErrorMessage = "";

      state.title = "";
      state.language = "";
      state.file = null;
      state.id = -1;
      state.type = 1;
    },
    addDocuemnt: (state, action: PayloadAction<{ document: Document }>) => {
      state.documents = [action.payload.document, ...state.documents];
      state.documentActionStart = false;
      state.documentErrorMessage = "";
    },
    updateDocument: (state, action: PayloadAction<{ document: Document }>) => {
      const documentUpdate = action.payload.document;

      const index = state.documents.findIndex(
        (docu) => docu.id === documentUpdate.id
      );
      if (index !== -1) {
        state.documents[index] = documentUpdate;
      } else {
        console.error("Document with given ID not found.");
      }

      state.documentActionStart = false;
      state.documentErrorMessage = "";
    },
    removeDocument: (state, action: PayloadAction<number>) => {
      state.documents = state.documents.filter(
        (doc) => doc.id !== action.payload
      );
      state.documentActionStart = false;
      state.documentErrorMessage = "";
    },
  },
});

export const {
  setDocumentActionStart,
  setDocumentErrorMessage,
  setDocuments,
  resetDocument,
  setDocumentSearchText,
  setDocumentTitle,
  setDocumentLanguage,
  setDocumentFile,
  setDocumentType,
  setDocument,
  addDocuemnt,
  updateDocument,
  removeDocument,
} = documentSlice.actions;
