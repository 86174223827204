import { Form } from "antd";
import { InputText } from "../../components/input";
import { useAppDispatch, useAppSelector } from "../../constants/hooks";
import { RootState } from "../../store";
import {
  useHandleForgotPasswordPasswordChange,
  useHandleForgotPasswordPasswordCheckChange,
} from "../../constants/utils";
import { MessageModal } from "../../components/modal";
import { setForgotPasswordErrorMessage } from "../../constants/reducer/forgot-password-reducer";
import {
  useAppNavigate,
  useAppState,
  useAppTranslation,
} from "../../constants/hooks/global-hooks";
import { LoadingIcon } from "../../components/icon";
import { useEffect } from "react";
import { forgotPasswordModifyPassword } from "../../actions/forgot-password";
import { CustomButton } from "../../components/button";

import "./forgot-password-confirm.scss";
export const ForgotPasswordConfirmPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();
  const navigate = useAppNavigate();
  const {
    isTokenValid,
    password,
    passwordCheck,
    errorMessage,
    forgotPasswordActionStart,
  } = useAppSelector((state: RootState) => state.forgotPassword);
  const changePw = useHandleForgotPasswordPasswordChange(dispatch);
  const changePwCheck = useHandleForgotPasswordPasswordCheckChange(dispatch);
  const [state, setState] = useAppState({
    loading: true,
    password: "",
    passwordCheck: "",
  });
  const handleModalClose = () => {
    dispatch(setForgotPasswordErrorMessage(""));
    if (errorMessage === "forgot-password-modify-password-success") {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (isTokenValid || isTokenValid === undefined || isTokenValid === null) {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  }, [isTokenValid, setState]);

  const handleConfirm = async () => {
    await forgotPasswordModifyPassword();
  };

  return (
    <main className="page forgot-password-confirm-page">
      {state.loading || forgotPasswordActionStart ? (
        <div>
          <LoadingIcon />
        </div>
      ) : isTokenValid !== null && isTokenValid !== undefined ? (
        <div className="form-container">
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Form.Item label={t("forgot-password-confirm-form-email-label")}>
              <InputText
                value={isTokenValid.email}
                disabled={true}
                onChange={undefined!}
              />
            </Form.Item>
            <Form.Item
              label={t("forgot-password-confirm-form-password-label")}
              required={true}
              name="password"
              rules={[
                {
                  required: true,
                  message: t(
                    "admin-page-create-user-modal-input-error-message"
                  ),
                },
                {
                  required: true,
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                  message: t("password-format-error-message"),
                },
              ]}
            >
              <InputText
                value={password}
                onChange={changePw}
                isPassword={true}
              />
            </Form.Item>
            <Form.Item
              label={t("forgot-password-confirm-form-password-check-label")}
              required={true}
              name="passwordCheck"
              rules={[
                {
                  required: true,
                  message: t(
                    "admin-page-create-user-modal-input-error-message"
                  ),
                },
                {
                  required: true,
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                  message: t("password-format-error-message"),
                },
              ]}
            >
              <InputText
                value={passwordCheck}
                onChange={changePwCheck}
                isPassword={true}
              />
            </Form.Item>
          </Form>
          <CustomButton
            title={t("forgot-password-confirm-form-submit-button-title")}
            onClick={handleConfirm}
            className="forgot-password-confirm-submit-button"
          />
          {errorMessage !== "" && (
            <MessageModal
              title=""
              message={errorMessage}
              onModalClose={handleModalClose}
              modalVisible={errorMessage !== ""}
            />
          )}
        </div>
      ) : (
        <div className="forgot-password-title">
          <span>{t(errorMessage)}</span>
        </div>
      )}
    </main>
  );
};
