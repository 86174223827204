import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";

import { BASE_METHODS } from "../../constants/enum";
import { CustomErrorType } from "../../constants/action-types";
import { timeoutError } from "../../constants/reducer/base-reducer";

import { CreateExchangeRateDTO } from "../../constants/dto/fund";
import {
  updateExchangeRate,
  setExchangeRateActionStart,
  setExchangeRateErrorMessage,
} from "../../constants/reducer/exchange-rate-reducer";

let body: CreateExchangeRateDTO;

export const modifyExchangeRate = async () => {
  store.dispatch(setExchangeRateActionStart(true));
  const { exchangeRate, netPrice, date, fundId, selectedId } =
    store.getState().exchangeRate;

  const params = {
    id: selectedId,
    exchangeRate,
    netPrice,
    date,
    fundId,
  };

  await createCall(
    "cms/exchange-rate",
    BASE_METHODS.PUT,
    "application/json",
    params,
    undefined
  )
    .then(async (response) => {
      try {
        body = plainToClass(
          CreateExchangeRateDTO,
          JSON.parse(await response.text())
        );
      } catch (error) {
        console.log(error);
        return;
      }
      if ((await validate(body)).length > 0) {
        store.dispatch(
          setExchangeRateErrorMessage(
            "modify-exchange-rate-invalid-response-error-message"
          )
        );
        return;
      }

      store.dispatch(updateExchangeRate({ exchangeRate: body.exchangeRate }));
    })
    .catch((error) => {
      const errorResponeData: CustomErrorType = error;
      if (errorResponeData) {
        let errorMessage = "";
        switch (errorResponeData.code) {
          case 3001:
            errorMessage = "create-exchange-rate-data-already-exist";
            break;
          case 3002:
            errorMessage = "modify-exchange-rate-data-fund-not-exist";
            break;
          default:
            break;
        }
        store.dispatch(setExchangeRateErrorMessage(errorMessage));
        return;
      }
      store.dispatch(setExchangeRateActionStart(false));
      store.dispatch(timeoutError());
      return;
    });
};
