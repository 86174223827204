import { Form, FormInstance, Select, Upload, Button } from "antd";
import { InputText } from "../../../components/input";
import {
  useAppDispatch,
  useAppSelector,
  useAppTranslation,
} from "../../../constants/hooks";
import { RootState } from "../../../store";
import {
  useHandleDocumentLanguageChange,
  useHandleDocumentTitleChange,
} from "../../../constants/utils";
import { useEffect } from "react";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/es/upload";
import { setDocumentFile } from "../../../constants/reducer/document-reducer";
import { useHandleDocumentTypeChange } from "../../../constants/utils/documents-utils";

type DocumentDetailsFormProps = {
  form: FormInstance<any>;
};

export const DocumentDetailsForm = (props: DocumentDetailsFormProps) => {
  const { t } = useAppTranslation();
  const { title, language, file, id, type } = useAppSelector(
    (state: RootState) => state.document
  );
  const token = useAppSelector((state: RootState) => state.session.token);

  const dispatch = useAppDispatch();

  const languageOptions = [
    { label: t("langugage_en_short_button"), value: "en" },
    { label: t("langugage_hu_short_button"), value: "hu" },
    { label: t("langugage_hr_short_button"), value: "hr" },
    { label: t("langugage_mne_short_button"), value: "mne" },
    { label: t("langugage_sl_short_button"), value: "sl" },
    { label: t("langugage_bg_short_button"), value: "bg" },
  ];

  const typeOptions = [
    { label: t("conservative_title"), value: 1 },
    { label: t("balanced_title"), value: 2 },
    { label: t("dynamic_title"), value: 3 },
  ];

  useEffect(() => {
    if (id) {
      props.form.setFieldsValue({
        title: title,
        language: language,
        file: file,
        type: type,
      });
    }
  }, [title, language, id, file, type]);

  const onMediaFileChange = (info: UploadChangeParam) => {
    if (info.file.status === "done") {
      const mediaFile = info.file.response.mediaFiles[0];
      dispatch(setDocumentFile(mediaFile));
    }
  };

  return (
    <Form
      form={props.form}
      name="document-details-form"
      labelCol={{ span: 0 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
    >
      <Form.Item
        label={
          <label className="form-item-label">
            {t("documents-page-column-name-title")}
          </label>
        }
        name="title"
        rules={[
          {
            required: true,
            message: t("admin-page-create-user-modal-input-error-message"),
          },
        ]}
      >
        <InputText
          value={title}
          onChange={useHandleDocumentTitleChange(dispatch)}
        />
      </Form.Item>
      <Form.Item
        label={<label>{t("documents-page-column-type-title")}</label>}
        name="type"
        rules={[
          {
            required: true,
            message: t("admin-page-create-user-modal-input-error-message"),
          },
        ]}
      >
        <Select
          className="form-search"
          dropdownStyle={{ fontFamily: "var(--font-primary)" }}
          showSearch
          optionFilterProp="children"
          onChange={useHandleDocumentTypeChange(dispatch)}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          value={type}
          options={typeOptions}
        />
      </Form.Item>

      <Form.Item
        label={<label>{t("documents-page-column-language-title")}</label>}
        name="language"
        rules={[
          {
            required: true,
            message: t("admin-page-create-user-modal-input-error-message"),
          },
        ]}
      >
        <Select
          className="form-search"
          dropdownStyle={{ fontFamily: "var(--font-primary)" }}
          showSearch
          optionFilterProp="children"
          onChange={useHandleDocumentLanguageChange(dispatch)}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          value={language}
          options={languageOptions}
        />
      </Form.Item>
      <Form.Item
        label={
          <label className="form-item-label">
            {t("documents-page-column-url-title")}
          </label>
        }
        name="file"
        rules={[
          {
            required: true,
            message: t("admin-page-create-user-modal-input-error-message"),
          },
        ]}
      >
        <Upload
          action={`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/media-files`}
          headers={{ Authorization: `Bearer ${token}` }}
          name="file"
          accept=".pdf"
          showUploadList={false}
          onChange={(info) => onMediaFileChange(info)}
        >
          <Button icon={<UploadOutlined />} size="large">
            {t("documents-page-upload-button")}
          </Button>

          {file ? (
            <div className="audio-container">
              <label className="form-item-label">{file.filename}</label>
              <Button
                danger
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => setDocumentFile(null)}
              />
            </div>
          ) : null}
        </Upload>
      </Form.Item>
    </Form>
  );
};
