import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ExchangeRateState } from "../state";
import { ExchangeRate } from "../entity";

const initialState: ExchangeRateState = {
  errorMessage: "",
  exchangeRateActionStart: false,
  exchangeRates: [],
  searchText: 1,
  date: null,
  exchangeRate: 0,
  netPrice: 0,
  fundId: null!,
  selectedId: null!,
};

export const exchangeRateSlice = createSlice({
  name: "exchangeRate",
  initialState,
  reducers: {
    setExchangeRates: (
      state,
      action: PayloadAction<{ exchangeRates: ExchangeRate[] }>
    ) => {
      state.exchangeRates = action.payload.exchangeRates;
      state.exchangeRateActionStart = false;
      state.errorMessage = "";
    },
    addExchangeRateToFund: (
      state,
      action: PayloadAction<{ exchangeRate: ExchangeRate }>
    ) => {
      state.exchangeRates = [
        action.payload.exchangeRate,
        ...state.exchangeRates,
      ];
      state.exchangeRateActionStart = false;
      state.errorMessage = "";
    },
    updateExchangeRate: (
      state,
      action: PayloadAction<{ exchangeRate: ExchangeRate }>
    ) => {
      const exchangeRateToUpdate = action.payload.exchangeRate;
      if (state.searchText !== exchangeRateToUpdate.fundId) {
        state.exchangeRates = state.exchangeRates.filter(
          (rate) => rate.id !== exchangeRateToUpdate.id
        );
      } else {
        const index = state.exchangeRates.findIndex(
          (rate) => rate.id === exchangeRateToUpdate.id
        );
        if (index !== -1) {
          state.exchangeRates[index] = exchangeRateToUpdate;
        } else {
          console.error("Exchange rate with given ID not found.");
        }
      }

      state.exchangeRateActionStart = false;
      state.errorMessage = "";
    },
    setExchangeRateErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
      state.exchangeRateActionStart = false;
    },
    setExchangeRateActionStart: (state, action: PayloadAction<boolean>) => {
      state.exchangeRateActionStart = action.payload;
    },
    setExchangeRateSearchText: (state, action: PayloadAction<number>) => {
      state.searchText = action.payload;
    },
    resetExchangeRate: (state) => {
      state.errorMessage = "";
      state.exchangeRateActionStart = false;
      state.date = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
      state.netPrice = 0;
      state.fundId = null!;
      state.exchangeRate = 0;
    },
    removeRateFromExchangeRates: (state, action: PayloadAction<number>) => {
      state.exchangeRates = state.exchangeRates.filter(
        (rate) => rate.id !== action.payload
      );
      state.exchangeRateActionStart = false;
      state.errorMessage = "";
    },
    setExchangeRateDate: (state, action: PayloadAction<string>) => {
      state.date = new Date(
        new Date(action.payload).setHours(0, 0, 0, 0)
      ).getTime();
    },
    setExchangeRateExRate: (state, action: PayloadAction<string>) => {
      state.exchangeRate = parseFloat(action.payload);
    },
    setExchangeRateNetPrice: (state, action: PayloadAction<string>) => {
      state.netPrice = parseFloat(action.payload);
    },
    setExchangeRateFundId: (state, action: PayloadAction<number>) => {
      state.fundId = action.payload;
    },
    setExchangeRateModify: (state, action: PayloadAction<ExchangeRate>) => {
      state.selectedId = action.payload.id;
      state.date = action.payload.date;
      state.exchangeRate = action.payload.exchangeRate;
      state.netPrice = action.payload.netPrice;
      state.fundId = action.payload.fundId;
    },
  },
});

export const {
  setExchangeRateActionStart,
  setExchangeRateErrorMessage,
  addExchangeRateToFund,
  setExchangeRates,
  resetExchangeRate,
  removeRateFromExchangeRates,
  setExchangeRateSearchText,
  setExchangeRateDate,
  setExchangeRateExRate,
  setExchangeRateNetPrice,
  setExchangeRateFundId,
  setExchangeRateModify,
  updateExchangeRate,
} = exchangeRateSlice.actions;
