import { LoaderFunctionArgs } from "react-router-dom";
import { store } from "../../store";
import { getAllMessage } from "../../actions/message";
import { resetMessage } from "../reducer/message-reducer";

export const messageLoader = async (args: LoaderFunctionArgs) => {
  store.dispatch(resetMessage());

  return await getAllMessage();
};
