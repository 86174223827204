import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ForgotPasswordState } from "../state";
import { ForgotPasswordConfirmDTO } from "../dto/forgot-password";

const initialState: ForgotPasswordState = {
  email: "",
  password: "",
  passwordCheck: "",
  errorMessage: "",
  isTokenValid: null!,
  token: null,
  forgotPasswordActionStart: false,
};

export const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    setForgotPasswordEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setForgotPasswordPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setForgotPasswordPasswordCheck: (state, action: PayloadAction<string>) => {
      state.passwordCheck = action.payload;
    },
    setForgotPasswordErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
      state.forgotPasswordActionStart = false;
    },
    setIsTokenValid: (
      state,
      action: PayloadAction<ForgotPasswordConfirmDTO>
    ) => {
      state.isTokenValid = action.payload;
      state.errorMessage = "";
      state.forgotPasswordActionStart = false;
    },
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
      state.forgotPasswordActionStart = false;
    },
    resetForgotPassword: (state) => {
      state.email = "";
      state.password = "";
      state.passwordCheck = "";
      state.token = null;
    },
    setForgotPasswordActionStart: (state, action: PayloadAction<boolean>) => {
      state.forgotPasswordActionStart = action.payload;
    },
  },
});

export const {
  setForgotPasswordEmail,
  setForgotPasswordPassword,
  setForgotPasswordPasswordCheck,
  setForgotPasswordErrorMessage,
  setIsTokenValid,
  setToken,
  resetForgotPassword,
  setForgotPasswordActionStart,
} = forgotPasswordSlice.actions;
