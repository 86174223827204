import React, { useEffect } from "react";
import { DatePicker, Form, FormInstance, Select } from "antd";
import dayjs from "dayjs";
import {
  useAppDispatch,
  useAppSelector,
  useAppTranslation,
} from "../../../constants/hooks";
import { InputText } from "../../../components/input";
import { RootState } from "../../../store";
import {
  useHandleExcahngeRateDateChenge,
  useHandleExcahngeRateExRateChenge,
  useHandleExcahngeRateFundIdChenge,
  useHandleExcahngeRateNetPriceChenge,
} from "../../../constants/utils";

const SelectorOptions = [
  {
    value: 1,
    label: "Konzervatív alapok",
  },
  {
    value: 2,
    label: "Kiegyensúlyozott alapok",
  },
  {
    value: 3,
    label: "Dinamikus alapok",
  },
];

type CreateExchangeRateFormProps = {
  form: FormInstance<any>;
};

export const CreateExchangeRateForm: React.FC<CreateExchangeRateFormProps> = ({
  form,
}) => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const { selectedId, date, exchangeRate, netPrice, fundId } = useAppSelector(
    (state: RootState) => state.exchangeRate
  );

  useEffect(() => {
    if (selectedId) {
      const formattedDate = dayjs(date);
      form.setFieldsValue({
        date: formattedDate,
        exRate: exchangeRate,
        netPrice: netPrice,
        type: SelectorOptions.find((op) => op.value === fundId),
      });
    }
  }, [selectedId, date, exchangeRate, form, netPrice, fundId]);

  return (
    <Form
      form={form}
      name="createExchangeRate"
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
    >
      <Form.Item
        label={
          <label className="form-item-label">
            {t("fund-page-create-exchange-rate-modal-date-label")}
          </label>
        }
        name="date"
        rules={[
          {
            required: true,
            message: t("admin-page-create-user-modal-input-error-message"),
          },
        ]}
      >
        <DatePicker
          className="form-item-label date-picker"
          style={{ width: "100%" }}
          onChange={useHandleExcahngeRateDateChenge(dispatch)}
          value={date}
          locale={{
            lang: {
              locale: "hu_HU",
              placeholder: "Válassz dátumot",
              today: "Ma",
              now: "Most",
              backToToday: "Vissza a mához",
              ok: "OK",
              clear: "Törlés",
              month: "Hónap",
              year: "Év",
              timeSelect: "Válassz időt",
              dateSelect: "Válassz dátumot",
              monthSelect: "Válassz hónapot",
              yearSelect: "Válassz évet",
              decadeSelect: "Válassz évtizedet",
              yearFormat: "YYYY",
              dateFormat: "YYYY/M/D",
              dayFormat: "D",
              dateTimeFormat: "YYYY/M/D HH:mm:ss",
              monthFormat: "MMMM",
              monthBeforeYear: true,
              previousMonth: "Előző hónap (PageUp)",
              nextMonth: "Következő hónap (PageDown)",
              previousYear: "Előző év (Control + left)",
              nextYear: "Következő év (Control + right)",
              previousDecade: "Előző évtized",
              nextDecade: "Következő évtized",
              previousCentury: "Last century",
              nextCentury: "Next century",
              shortWeekDays: ["V", "H", "K", "Sze", "Cs", "P", "Szo"],
              shortMonths: [
                "Jan",
                "Feb",
                "Már",
                "Ápr",
                "Máj",
                "Jún",
                "Júl",
                "Aug",
                "Szept",
                "Okt",
                "Nov",
                "Dec",
              ],
            },
            timePickerLocale: {
              placeholder: "Válassz időt",
            },
            dateFormat: "YYYY-MM-DD",
            dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
            weekFormat: "YYYY-wo",
            monthFormat: "YYYY-MM",
          }}
          disabledDate={(currDate) => currDate.isAfter()}
        />
      </Form.Item>
      <Form.Item
        label={
          <label className="form-item-label">
            {t("fund-page-create-exchange-rate-modal-exchange-rate-label")}
          </label>
        }
        name="exRate"
        rules={[
          {
            required: true,
            message: t("admin-page-create-user-modal-input-error-message"),
          },
        ]}
      >
        <InputText
          value={exchangeRate.toString()}
          onChange={useHandleExcahngeRateExRateChenge(dispatch)}
        />
      </Form.Item>
      <Form.Item
        label={
          <label>
            {t("fund-page-create-exchange-rate-modal-net-price-label")}
          </label>
        }
        name="netPrice"
        rules={[
          {
            required: true,
            message: t("admin-page-create-user-modal-input-error-message"),
          },
        ]}
      >
        <InputText
          value={netPrice.toString()}
          onChange={useHandleExcahngeRateNetPriceChenge(dispatch)}
        />
      </Form.Item>
      <Form.Item
        label={
          <label>
            {t("fund-page-create-exchange-rate-modal-fund-type-label")}
          </label>
        }
        name="type"
        rules={[
          {
            required: true,
            message: t("admin-page-create-user-modal-input-error-message"),
          },
        ]}
      >
        <Select
          className="form-search"
          dropdownStyle={{ fontFamily: "var(--font-primary)" }}
          showSearch
          optionFilterProp="children"
          onChange={useHandleExcahngeRateFundIdChenge(dispatch)}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={SelectorOptions}
        />
      </Form.Item>
    </Form>
  );
};
