import { LoaderFunctionArgs } from "react-router-dom";
import { store } from "../../store";
import { resetExchangeRate } from "../reducer/exchange-rate-reducer";
import { getAllExchangeRate } from "../../actions/exchange-rate";

export const fundLoader = async (args: LoaderFunctionArgs) => {
  store.dispatch(resetExchangeRate());

  return await getAllExchangeRate();
};
