import { useCallback } from "react";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useAppMemo, useAppState } from "../../constants/hooks/global-hooks";

type TextBoxProps = {
  value: string;
  onChange: (string: string) => void;
  disabled?: boolean;
  isPassword?: boolean;
  placeholder?: string;
  isLogin?: boolean;
  customErrorMessage?: string;
  search?: boolean;
};

export const InputText: React.FC<TextBoxProps> = ({
  value,
  onChange,
  disabled,
  isPassword,
  placeholder,
  customErrorMessage,
}) => {
  const [state, setState] = useAppState({
    passwordVisible: false,
  });

  const handleVisible = useCallback(() => {
    setState(() => ({
      passwordVisible: !state.passwordVisible,
    }));
  }, [setState, state.passwordVisible]);

  return useAppMemo(() => {
    return (
      <div>
        {!isPassword ? (
          <Input
            className="input"
            maxLength={255}
            onChange={(e) => onChange(e.target.value)}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
          />
        ) : (
          <Input
            className="input"
            type={!state.passwordVisible ? "password" : "text"}
            maxLength={255}
            onChange={(e) => onChange(e.target.value)}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            suffix={
              state.passwordVisible ? (
                <EyeTwoTone onClick={handleVisible} />
              ) : (
                <EyeInvisibleOutlined onClick={handleVisible} />
              )
            }
          />
        )}
        <div>
          <span className="input-error-message">
            {customErrorMessage ? customErrorMessage : ""}
          </span>
        </div>
      </div>
    );
  }, [
    onChange,
    disabled,
    isPassword,
    placeholder,
    value,
    customErrorMessage,
    handleVisible,
    state.passwordVisible,
  ]);
};
