import {
  useAppMemo,
  useAppSelector,
  useAppTranslation,
} from "../../../constants/hooks";
import { Admin } from "../../../constants/entity";
import { CustomPopConfirm } from "../../../components/popconfirm";
import { deleteAdmin } from "../../../actions/user";
import { DeleteIcon } from "../../../components/icon";
import { RootState } from "../../../store";
import { ROLE_ENUM } from "../../../constants/enum";

export const AdminColumns = () => {
  const { t } = useAppTranslation();
  const { authenticatedUser } = useAppSelector(
    (state: RootState) => state.session
  );

  const handleDelete = async (id: number) => {
    await deleteAdmin({ id });
  };

  return useAppMemo(() => {
    const colums = [
      {
        title: t("admin-page-admin-column-name-title"),
        key: t("admin-page-admin-column-name-title"),
        dataIndex: "name",
        width: "350px",
      },
      {
        title: t("admin-page-admin-column-email-title"),
        key: t("admin-page-admin-column-email-title"),
        dataIndex: "email",
        width: "350px",
      },
      {
        title: t("admin-page-admin-column-delete-title"),
        key: "delete",
        render: (text: string, record: Admin) => {
          return (
            <CustomPopConfirm
              title={t("admin-page-admin-column-delete-popup-confirm")}
              okText={t("yes")}
              cancelText={t("no")}
              onConfirm={() => handleDelete(record.id)}
            >
              <button className="table-delete-button">
                <DeleteIcon />
              </button>
            </CustomPopConfirm>
          );
        },
        width: "100px",
      },
    ];
    if (authenticatedUser?.role.id !== ROLE_ENUM.SUPER_ADMIN) {
      return colums.filter((col) => col.key !== "delete");
    }
    return colums;
  }, [t]);
};
