import { Expose } from "class-transformer";
import { IsDefined, IsNumber, IsString } from "class-validator";

export class ForgotPasswordConfirmDTO {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  email!: string;
}
