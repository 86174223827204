import { Form } from "antd";
import React from "react";
import {
  useAppDispatch,
  useAppSelector,
  useAppTranslation,
} from "../../../constants/hooks";
import { InputText } from "../../../components/input";
import { RootState } from "../../../store";
import { useHandleAdminEmailChange } from "../../../constants/utils";

export const CreateAdminForm: React.FC = () => {
  const { t } = useAppTranslation();
  const { email } = useAppSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();

  return (
    <Form
      name="basic"
      labelCol={{ span: 0 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      autoComplete="off"
    >
      <Form.Item
        label={
          <label className="form-item-label">
            {t("admin-page-create-user-modal-input-label")}
          </label>
        }
        name=""
        rules={[
          {
            required: true,
            message: t("admin-page-create-user-modal-input-error-message"),
          },
        ]}
      >
        <InputText
          value={email}
          onChange={useHandleAdminEmailChange(dispatch)}
        />
      </Form.Item>
    </Form>
  );
};
