import { useEffect } from "react";
import { CustomModal } from "../../components/modal";
import { SearchBar } from "../../components/searchbar";
import { DataTable } from "../../components/table";
import {
  useAppDispatch,
  useAppSelector,
  useAppTranslation,
  useDebounce,
} from "../../constants/hooks";
import { useAppState } from "../../constants/hooks/global-hooks";
import {
  useHandleGetAllMessage,
  useHandleMessageSearchChange,
} from "../../constants/utils";
import { RootState } from "../../store";
import { MessageColumns, MessageDetailsForm } from "./components";
import "./message.scss";
import Papa from "papaparse";

export const MessagePage = () => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const { messages, searchText } = useAppSelector(
    (state: RootState) => state.message
  );
  const [state, setState] = useAppState({
    modalVisible: false,
  });

  const getAllMessage = useHandleGetAllMessage();

  const handleModalVisible = () => {
    setState((prevState) => ({
      ...prevState,
      modalVisible: !state.modalVisible,
    }));
  };

  const debouncedUsers = useDebounce(
    getAllMessage,
    searchText.trim() !== "" ? 500 : 0
  );

  useEffect(() => {
    debouncedUsers();
  }, [debouncedUsers]);

  const exportCSV = () => {
    const csv = Papa.unparse(messages);
    const csvData = new Blob([csv], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvData);
    const a = document.createElement("a");
    a.href = csvUrl;
    a.download = `üzenetek ${new Date().getTime()}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="page message-page">
      <div className="title-text-h2">
        {t("message-page-page-title")}
        <button className="export-button" onClick={exportCSV}>
          {t("message-page-export-button-title")}
        </button>
      </div>
      <div className="searchbar-container">
        <SearchBar
          value={searchText}
          onChange={useHandleMessageSearchChange(dispatch)}
          placeholder={t("message-page-searchbar-placeholder")}
        />
      </div>

      <DataTable
        columns={Object.values(MessageColumns({ handleModalVisible }))}
        dataSource={messages}
      />
      <CustomModal
        modalVisible={state.modalVisible}
        onModalClose={handleModalVisible}
        title={"message-page-message-details-modal-title"}
        isDetail={true}
      >
        <MessageDetailsForm />
      </CustomModal>
    </div>
  );
};
