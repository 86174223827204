import { Link } from "react-router-dom";
import { useAppMemo } from "../../constants/hooks";

export const MainLogo = () => {
  return useAppMemo(() => {
    return (
      <Link to={"/funds"}>
        <img src="/image/OTP.BD-4b37445c.png" alt="logo" />
      </Link>
    );
  }, []);
};
