import { useCallback } from "react";
import {
  setAdminEmail,
  setAdminErrorMessage,
  setAdminName,
  setAdminPassword,
  setAdminSearchText,
} from "../reducer/user-reducer";
import { getAllAdmin } from "../../actions/user";

export const useHandleAdminAlertMessage = (dispatch: Function) => {
  const handleAlertMessage = useCallback(() => {
    dispatch(setAdminErrorMessage(""));
  }, [dispatch]);
  return handleAlertMessage;
};

export const useHandlAdminPwCheckChange = (setState: Function) => {
  const handlePwCheckChange = useCallback(
    (value: string) => {
      setState((prevState: any) => ({ ...prevState, pwCheck: value }));
    },
    [setState]
  );
  return handlePwCheckChange;
};

export const useHandleAdminPasswordChange = (dispatch: Function) => {
  const handlePasswordChange = useCallback(
    (value: string) => {
      dispatch(setAdminPassword(value));
    },
    [dispatch]
  );
  return handlePasswordChange;
};

export const useHandleAdminSearchChange = (dispatch: Function) => {
  const handleSearchChange = useCallback(
    (value: string) => {
      dispatch(setAdminSearchText(value));
    },
    [dispatch]
  );
  return handleSearchChange;
};

export const useHandleAdminEmailChange = (dispatch: Function) => {
  const handleAdminEmailChange = useCallback(
    (value: string) => {
      dispatch(setAdminEmail(value));
    },
    [dispatch]
  );
  return handleAdminEmailChange;
};

export const useHandleAdminNameChange = (dispatch: Function) => {
  const handleAdminNameChange = useCallback(
    (value: string) => {
      dispatch(setAdminName(value));
    },
    [dispatch]
  );
  return handleAdminNameChange;
};

export const useHandleGetAllUser = () => {
  const getAdmins = useCallback(async () => {
    await getAllAdmin();
  }, []);
  return getAdmins;
};
