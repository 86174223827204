import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";

import { BASE_METHODS } from "../../constants/enum";
import { GetMeDTO } from "../../constants/dto/user";
import { CustomErrorType } from "../../constants/action-types";
import { timeoutError } from "../../constants/reducer/base-reducer";
import {
  addAdminToAdmins,
  setAdminActionStart,
  setAdminErrorMessage,
} from "../../constants/reducer/user-reducer";

let body: GetMeDTO;

export const createAdmin = async () => {
  store.dispatch(setAdminActionStart(true));
  const { email } = store.getState().user;
  const params = {
    email,
  };
  await createCall(
    "cms/users/create",
    BASE_METHODS.POST,
    "application/json",
    params,
    undefined
  )
    .then(async (response) => {
      try {
        body = plainToClass(GetMeDTO, JSON.parse(await response.text()));
      } catch (error) {
        console.log(error);
        return;
      }
      if ((await validate(body)).length > 0) {
        store.dispatch(
          setAdminErrorMessage("create-admin-invalid-response-error-message")
        );
        return;
      }

      store.dispatch(addAdminToAdmins({ user: body.user }));
    })
    .catch((error) => {
      const errorResponeData: CustomErrorType = error;
      if (errorResponeData) {
        let errorMessage = "";
        switch (errorResponeData.code) {
          case 1003:
            errorMessage = "create-admin-user-already-exist";
            break;
          default:
            break;
        }
        store.dispatch(setAdminErrorMessage(errorMessage));
        return;
      }
      store.dispatch(setAdminActionStart(false));
      store.dispatch(timeoutError());
      return;
    });
};
