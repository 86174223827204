import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { store } from "../../store";

import { createCall } from "../base";

import { BASE_METHODS } from "../../constants/enum";
import { getMeFailure, getMeOk } from "../../constants/reducer/session-reducer";
import { GetMeDTO } from "../../constants/dto/user";

let body: GetMeDTO;

export const getMe = async () => {
  await createCall(
    "cms/users/me",
    BASE_METHODS.GET,
    "application/json",
    undefined,
    undefined
  )
    .then(async (response) => {
      try {
        body = plainToClass(GetMeDTO, JSON.parse(await response.text()));
      } catch (error) {
        console.log(error);
        return;
      }
      if ((await validate(body)).length > 0) {
        store.dispatch(getMeFailure());
        return;
      }

      store.dispatch(getMeOk({ user: body.user }));
    })
    .catch((error) => {
      store.dispatch(getMeFailure());
      return;
    });
};
