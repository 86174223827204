import { useCallback } from "react";
import {
  setDocumentErrorMessage,
  setDocumentFile,
  setDocumentLanguage,
  setDocumentSearchText,
  setDocumentTitle,
  setDocumentType,
} from "../reducer/document-reducer";
import { getAllDocument } from "../../actions/document";
import { MediaFile } from "../entity/media-file-entity";

export const useHandleDocumentSearchChange = (dispatch: Function) => {
  const handleSearchChange = useCallback(
    (value: string) => {
      dispatch(setDocumentSearchText(value));
    },
    [dispatch]
  );
  return handleSearchChange;
};

export const useHandleGetAllDocuments = () => {
  const getAllDocuments = useCallback(async () => {
    await getAllDocument();
  }, []);
  return getAllDocuments;
};

export const useHandleDocumentTitleChange = (dispatch: Function) => {
  const handleDocumentTitleChange = useCallback(
    (value: string) => {
      dispatch(setDocumentTitle(value));
    },
    [dispatch]
  );
  return handleDocumentTitleChange;
};

export const useHandleDocumentLanguageChange = (dispatch: Function) => {
  const handleDocumentLanguageChange = useCallback(
    (value: string) => {
      dispatch(setDocumentLanguage(value));
    },
    [dispatch]
  );
  return handleDocumentLanguageChange;
};

export const useHandleDocumentTypeChange = (dispatch: Function) => {
  const handleDocumentTypeChange = useCallback(
    (value: number) => {
      dispatch(setDocumentType(value));
    },
    [dispatch]
  );
  return handleDocumentTypeChange;
};

export const useHandleDocumentFileChange = (dispatch: Function) => {
  const handleDocumentFileChange = useCallback(
    (value: MediaFile | null) => {
      dispatch(setDocumentFile(value));
    },
    [dispatch]
  );
  return handleDocumentFileChange;
};

export const useHandleDocumenteErrorMessageChange = (dispatch: Function) => {
  const handleErrorMessageChange = useCallback(() => {
    dispatch(setDocumentErrorMessage(""));
  }, [dispatch]);
  return handleErrorMessageChange;
};
