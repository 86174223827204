import { SnippetsOutlined } from "@ant-design/icons";
import { Message } from "../../../constants/entity";
import {
  useAppDispatch,
  useAppMemo,
  useAppTranslation,
} from "../../../constants/hooks";
import { setMessage } from "../../../constants/reducer/message-reducer";

type MessageColumnsProps = {
  handleModalVisible: () => void;
};

export const MessageColumns = ({ handleModalVisible }: MessageColumnsProps) => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const handleGetMessage = (record: Message) => {
    dispatch(setMessage({ message: record }));
    handleModalVisible();
  };

  return useAppMemo(() => {
    return [
      {
        title: t("message-page-column-email-title"),
        key: t("message-page-column-email-title"),
        dataIndex: "email",
        width: "20%",
      },
      {
        title: t("message-page-column-name-title"),
        key: t("message-page-column-name-title"),
        dataIndex: "name",
        width: "10%",
      },
      {
        title: t("message-page-column-phone-title"),
        key: t("message-page-column-phone-title"),
        dataIndex: "phone",
        width: "10%",
      },
      {
        title: t("message-page-column-message-title"),
        key: t("message-page-column-message-title"),
        dataIndex: "message",

        render: (text: string, record: Message): JSX.Element => {
          return (
            <span>
              {record.message.length >= 100
                ? record.message.substring(0, 100) + "..."
                : record.message}
            </span>
          );
        },
      },
      {
        title: t("message-page-column-message-type-title"),
        key: t("message-page-column-message-type-title"),
        dataIndex: "type",
        render: (text: string, record: Message): JSX.Element => {
          return (
            <span>
              {record.type === 1
                ? t("message-page-column-message-type-one")
                : t("message-page-column-message-type-two")}
            </span>
          );
        },
        filters: [
          { text: t("message-page-column-message-type-one"), value: 1 },
          { text: t("message-page-column-message-type-two"), value: 2 },
        ],
        onFilter: (value: number, record: Message) => record.type === value,
        width: "20%",
      },
      {
        title: " ",
        key: "details",
        render: (text: string, record: Message) => {
          return <SnippetsOutlined onClick={() => handleGetMessage(record)} />;
        },
      },
    ];
  }, []);
};
