import { useEffect } from "react";
import { DataTable } from "../../components/table";
import {
  useAppDispatch,
  useAppSelector,
  useAppTranslation,
} from "../../constants/hooks";
import { RootState } from "../../store";
import {
  useHandleExcahngeRateErrorMessageChenge,
  useHandleExchangeRateSearchChange,
} from "../../constants/utils";
import { CustomButton } from "../../components/button";
import { useAppState } from "../../constants/hooks/global-hooks";
import { CustomModal, MessageModal } from "../../components/modal";
import { CreateExchangeRateForm, FundColumns } from "./components";
import {
  createExchangeRate,
  modifyExchangeRate,
} from "../../actions/exchange-rate";
import { resetExchangeRate } from "../../constants/reducer/exchange-rate-reducer";
import { Form } from "antd";

import "./fund-page.scss";

export const FundPage = () => {
  const [form] = Form.useForm();
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const { exchangeRates, searchText, errorMessage, selectedId } =
    useAppSelector((state: RootState) => state.exchangeRate);
  const baseReducer = useAppSelector((state: RootState) => state.base);

  const [state, setState] = useAppState({
    modalVisible: false,
    clicked: false,
  });

  const resetErrorMessage = useHandleExcahngeRateErrorMessageChenge(dispatch);

  const handleModalVisible = () => {
    if (state.modalVisible === true) {
      dispatch(resetExchangeRate());
      form.resetFields();
    }
    setState((prevState) => ({
      ...prevState,
      modalVisible: !state.modalVisible,
    }));
  };

  const handleCreateData = async () => {
    if (!selectedId) {
      await createExchangeRate();
    } else {
      await modifyExchangeRate();
    }
    setState((prevState) => ({ ...prevState, clicked: true }));
  };

  useEffect(() => {
    if (
      state.clicked &&
      errorMessage === "" &&
      baseReducer.errorMessage === ""
    ) {
      setState((prevState) => ({
        ...prevState,
        modalVisible: false,
        clicked: false,
      }));
      form.resetFields();
      dispatch(resetExchangeRate());
    }
    setState((prevState) => ({ ...prevState, clicked: false }));
  }, [
    state.clicked,
    errorMessage,
    setState,
    dispatch,
    baseReducer.errorMessage,
    form,
  ]);

  return (
    <main className="page fund-page">
      <div className="title-text-h2">{t("fund-page-page-title")}</div>
      <div className="button-container">
        <div className="link-buttons">
          <button
            className={`selector-button ${searchText === 1 ? "active" : ""}`}
            onClick={useHandleExchangeRateSearchChange(dispatch, 1)}
          >
            <div className={`link`}>{t("fund-page-konservative-fund")}</div>
          </button>
          <button
            className={`selector-button ${searchText === 2 ? "active" : ""}`}
            onClick={useHandleExchangeRateSearchChange(dispatch, 2)}
          >
            <div className={`link`}>{t("fund-page-balanced-fund")}</div>
          </button>
          <button
            className={`selector-button ${searchText === 3 ? "active" : ""}`}
            onClick={useHandleExchangeRateSearchChange(dispatch, 3)}
          >
            <div className={`link`}>{t("fund-page-dinamic-fund")}</div>
          </button>
        </div>

        <div className="creation-container">
          <CustomButton
            title="+"
            className="fund-creation-button"
            onClick={handleModalVisible}
          />
        </div>
      </div>
      <DataTable
        dataSource={exchangeRates.filter(
          (exRate) => exRate.fundId === searchText
        )}
        columns={Object.values(FundColumns({ handleModalVisible }))}
      />

      <CustomModal
        title={"fund-page-create-exchange-rate-modal-title"}
        onModalClose={handleModalVisible}
        onConfirm={handleCreateData}
        modalVisible={state.modalVisible}
      >
        <CreateExchangeRateForm form={form} />
      </CustomModal>
      <MessageModal
        modalVisible={errorMessage !== ""}
        title={t("timeout-error-title")}
        message={errorMessage}
        errorTitle={true}
        onOkClick={resetErrorMessage}
      />
    </main>
  );
};
