import { Popconfirm } from "antd";
import "./popconfirm.scss";

interface PopconfirmProps {
  children: JSX.Element;
  title: string;
  okText: string;
  cancelText: string;
  onConfirm: () => void;
}

export const CustomPopConfirm: React.FC<PopconfirmProps> = ({
  children,
  title,
  okText,
  cancelText,
  onConfirm,
}) => {
  return (
    <Popconfirm
      title={title}
      okText={okText}
      cancelText={cancelText}
      onConfirm={onConfirm}
      cancelButtonProps={{ style: { borderRadius: "24px" } }}
      okButtonProps={{
        style: { backgroundColor: "#52ae30", borderRadius: "24px" },
      }}
    >
      {children}
    </Popconfirm>
  );
};
