import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { BASE_METHODS } from "../../constants/enum";
import { CustomErrorType } from "../../constants/action-types";
import { timeoutError } from "../../constants/reducer/base-reducer";
import {
  setDocumentActionStart,
  setDocumentErrorMessage,
  updateDocument,
} from "../../constants/reducer/document-reducer";
import { CreateDocumentDTO } from "../../constants/dto/document";

let body: CreateDocumentDTO;

export const modifyDocument = async () => {
  store.dispatch(setDocumentActionStart(true));
  const { title, language, file, id, type } = store.getState().document;

  const params = {
    id,
    title,
    language,
    type,
    fileId: file ? file.id : -1,
  };

  await createCall(
    "cms/documents",
    BASE_METHODS.PUT,
    "application/json",
    params,
    undefined
  )
    .then(async (response) => {
      try {
        body = plainToClass(
          CreateDocumentDTO,
          JSON.parse(await response.text())
        );
      } catch (error) {
        console.log(error);
        return;
      }
      if ((await validate(body)).length > 0) {
        store.dispatch(
          setDocumentErrorMessage(
            "modify-exchange-rate-invalid-response-error-message"
          )
        );
        return;
      }

      store.dispatch(updateDocument({ document: body.document }));
    })
    .catch((error) => {
      const errorResponeData: CustomErrorType = error;
      if (errorResponeData) {
        let errorMessage = "";
        switch (errorResponeData.code) {
          case 3001:
            errorMessage = "create-exchange-rate-data-already-exist";
            break;
          case 3002:
            errorMessage = "modify-exchange-rate-data-fund-not-exist";
            break;
          default:
            break;
        }
        store.dispatch(setDocumentErrorMessage(errorMessage));
        return;
      }
      store.dispatch(setDocumentActionStart(false));
      store.dispatch(timeoutError());
      return;
    });
};
