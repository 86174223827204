import { Expose } from "class-transformer";
import { IsDefined, IsNumber, IsString } from "class-validator";

export class Session {
  @Expose()
  @IsDefined()
  @IsString()
  accessToken!: string;

  @Expose()
  @IsDefined()
  @IsNumber()
  expirationDate!: number;
}
