import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { BASE_METHODS } from "../../constants/enum";
import { CustomErrorType } from "../../constants/action-types";
import { timeoutError } from "../../constants/reducer/base-reducer";
import {
  resetForgotPassword,
  setForgotPasswordActionStart,
  setForgotPasswordErrorMessage,
} from "../../constants/reducer/forgot-password-reducer";
import { SuccessDTO } from "../../constants/dto/success";

let body: SuccessDTO;

export const forgotPasswordModifyPassword = async () => {
  store.dispatch(setForgotPasswordActionStart(true));
  const { isTokenValid, token, password } = store.getState().forgotPassword;
  const bodyToSend = {
    id: isTokenValid?.id,
    token,
    password,
  };
  await createCall(
    "cms/users/modify-password",
    BASE_METHODS.PUT,
    "application/json",
    bodyToSend,
    undefined,
    true
  )
    .then(async (response) => {
      try {
        body = plainToClass(SuccessDTO, JSON.parse(await response.text()));
      } catch (error) {
        console.log(error);
        return;
      }
      if ((await validate(body)).length > 0) {
        store.dispatch(
          setForgotPasswordErrorMessage(
            "forgot-password-modify-password-invalid-response-error-message"
          )
        );
        return;
      }

      store.dispatch(
        setForgotPasswordErrorMessage("forgot-password-modify-password-success")
      );
      store.dispatch(resetForgotPassword());
      return;
    })
    .catch((error) => {
      const errorResponeData: CustomErrorType = error;
      if (errorResponeData) {
        let errorMessage = "";
        switch (errorResponeData.code) {
          case 1001:
            errorMessage = "forgot-password-modify-password-user-not-found";
            break;

          default:
            break;
        }
        store.dispatch(setForgotPasswordErrorMessage(errorMessage));
        return;
      }
      store.dispatch(setForgotPasswordActionStart(false));
      store.dispatch(timeoutError());
      return;
    });
};
