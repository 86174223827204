import { deleteExchangeRate } from "../../../actions/exchange-rate";
import { DeleteIcon, EditIcon } from "../../../components/icon";
import { CustomPopConfirm } from "../../../components/popconfirm";
import { ExchangeRate } from "../../../constants/entity";
import {
  useAppDispatch,
  useAppMemo,
  useAppTranslation,
} from "../../../constants/hooks";
import { setExchangeRateModify } from "../../../constants/reducer/exchange-rate-reducer";

type FundColumnsProps = {
  handleModalVisible: () => void;
};

export const FundColumns = ({ handleModalVisible }: FundColumnsProps) => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();

  const handleDelete = async (id: number) => {
    await deleteExchangeRate({ id });
  };

  const handleModify = (record: ExchangeRate) => {
    dispatch(setExchangeRateModify(record));
    handleModalVisible();
  };

  return useAppMemo(() => {
    return [
      {
        title: t("fund-page-table-date-header-title"),
        key: t("fund-page-table-date-header-title"),
        dataIndex: "date",
        sorter: (a: ExchangeRate, b: ExchangeRate) => a.date - b.date,
        render: (text: string, record: ExchangeRate) => {
          return new Date(record.date).toLocaleDateString();
        },
        width: "30%",
      },
      {
        title: t("fund-page-table-exchangeRate-header-title"),
        key: t("fund-page-table-exchangeRate-header-title"),
        dataIndex: "exchangeRate",
        sorter: (a: ExchangeRate, b: ExchangeRate) =>
          a.exchangeRate - b.exchangeRate,
        width: "30%",
      },
      {
        title: t("fund-page-table-netPrice-header-title"),
        key: t("fund-page-table-netPrice-header-title"),
        dataIndex: "netPrice",
        sorter: (a: ExchangeRate, b: ExchangeRate) => a.netPrice - b.netPrice,
        width: "30%",
      },
      {
        title: t("fund-page-exchange-rate-column-header-modify-title"),
        key: t("fund-page-exchange-rate-column-header-modify-title"),
        render: (text: string, record: ExchangeRate) => {
          return <EditIcon onClick={() => handleModify(record)} />;
        },
        width: "5%",
      },
      {
        title: t("fund-page-exchange-rate-column-delete-title"),
        key: t("fund-page-exchange-rate-column-delete-title"),
        render: (text: string, record: ExchangeRate) => {
          return (
            <CustomPopConfirm
              title={t("fund-page-exchange-rate-column-delete-popup-confirm")}
              okText={t("yes")}
              cancelText={t("no")}
              onConfirm={() => handleDelete(record.id)}
            >
              <button className="table-delete-button">
                <DeleteIcon />
              </button>
            </CustomPopConfirm>
          );
        },
        width: "5%",
      },
    ];
  }, [t]);
};
