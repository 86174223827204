import { LoaderFunctionArgs } from "react-router-dom";
import { store } from "../../store";
import { getAllAdmin } from "../../actions/user";
import { resetAdmin } from "../reducer/user-reducer";

export const adminLoader = async (args: LoaderFunctionArgs) => {
  store.dispatch(resetAdmin());

  return await getAllAdmin();
};
