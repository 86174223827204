import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";

import { BASE_METHODS } from "../../constants/enum";
import { timeoutError } from "../../constants/reducer/base-reducer";

import {
  setExchangeRateActionStart,
  setExchangeRateErrorMessage,
  setExchangeRates,
} from "../../constants/reducer/exchange-rate-reducer";
import { GetAllExchangeRateDTO } from "../../constants/dto/fund";

let body: GetAllExchangeRateDTO;

export const getAllExchangeRate = async () => {
  store.dispatch(setExchangeRateActionStart(true));

  await createCall("cms/exchange-rate", BASE_METHODS.GET, "application/json")
    .then(async (response) => {
      try {
        body = plainToClass(
          GetAllExchangeRateDTO,
          JSON.parse(await response.text())
        );
      } catch (error) {
        console.log(error);
        return;
      }
      if ((await validate(body)).length > 0) {
        store.dispatch(
          setExchangeRateErrorMessage("get-all-exchange-rate-invalid-response")
        );
        return;
      }
      store.dispatch(setExchangeRates({ exchangeRates: body.exchangeRates }));
    })
    .catch((error) => {
      store.dispatch(setExchangeRateActionStart(false));
      store.dispatch(timeoutError());
    });
  return null;
};
