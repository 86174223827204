import { useCallback } from "react";
import {
  setExchangeRateDate,
  setExchangeRateErrorMessage,
  setExchangeRateExRate,
  setExchangeRateFundId,
  setExchangeRateNetPrice,
  setExchangeRateSearchText,
} from "../reducer/exchange-rate-reducer";
import { getAllExchangeRate } from "../../actions/exchange-rate";

export const useHandleExchangeRateSearchChange = (
  dispatch: Function,
  value: number
) => {
  const handleSearchChange = useCallback(() => {
    dispatch(setExchangeRateSearchText(value));
  }, [dispatch, value]);
  return handleSearchChange;
};

export const useHandleGetExchangeRate = () => {
  const getExchangeRates = useCallback(async () => {
    await getAllExchangeRate();
  }, []);
  return getExchangeRates;
};

export const useHandleExcahngeRateDateChenge = (dispatch: Function) => {
  const handleDateChange = useCallback(
    (value: any) => {
      dispatch(setExchangeRateDate(value));
    },
    [dispatch]
  );
  return handleDateChange;
};

export const useHandleExcahngeRateExRateChenge = (dispatch: Function) => {
  const handleExRateChange = useCallback(
    (value: string) => {
      dispatch(setExchangeRateExRate(value));
    },
    [dispatch]
  );
  return handleExRateChange;
};

export const useHandleExcahngeRateNetPriceChenge = (dispatch: Function) => {
  const handlenetPriceChange = useCallback(
    (value: string) => {
      dispatch(setExchangeRateNetPrice(value));
    },
    [dispatch]
  );
  return handlenetPriceChange;
};

export const useHandleExcahngeRateFundIdChenge = (dispatch: Function) => {
  const handleFundIdChange = useCallback(
    (value: number) => {
      dispatch(setExchangeRateFundId(value));
    },
    [dispatch]
  );
  return handleFundIdChange;
};

export const useHandleExcahngeRateErrorMessageChenge = (dispatch: Function) => {
  const handleErrorMessageChange = useCallback(() => {
    dispatch(setExchangeRateErrorMessage(""));
  }, [dispatch]);
  return handleErrorMessageChange;
};
