import { store } from "../../store";
import { createCall } from "../base";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { BASE_METHODS } from "../../constants/enum";
import { timeoutError } from "../../constants/reducer/base-reducer";
import { GetAllDocumentDTO } from "../../constants/dto/document";
import {
  setDocumentActionStart,
  setDocumentErrorMessage,
  setDocuments,
} from "../../constants/reducer/document-reducer";

let body: GetAllDocumentDTO;

export const getAllDocument = async () => {
  store.dispatch(setDocumentActionStart(true));

  const { searchText } = store.getState().document;
  const queryParams = new URLSearchParams();
  if (searchText.trim() !== "")
    queryParams.append("searchText", searchText.trim());

  await createCall(
    "cms/documents",
    BASE_METHODS.GET,
    "application/json",
    undefined,
    queryParams
  )
    .then(async (response) => {
      try {
        body = plainToClass(
          GetAllDocumentDTO,
          JSON.parse(await response.text())
        );
      } catch (error) {
        console.log(error);
        return;
      }

      if ((await validate(body)).length > 0) {
        store.dispatch(
          setDocumentErrorMessage("get-all-document-invalid-response")
        );
        return;
      }
      store.dispatch(setDocuments({ documents: body.documents }));
    })
    .catch((error) => {
      store.dispatch(setDocumentActionStart(false));
      store.dispatch(timeoutError());
    });
  return null;
};
