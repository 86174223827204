import { useEffect } from "react";
import { firstLogin } from "../../actions/user";
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from "../../constants/hooks";
import {
  useAppState,
  useAppTranslation,
} from "../../constants/hooks/global-hooks";
import {
  useHandlAdminPwCheckChange,
  useHandleAdminAlertMessage,
  useHandleAdminPasswordChange,
} from "../../constants/utils";
import { RootState } from "../../store";
import { ROLE_ENUM } from "../../constants/enum";
import { InputText } from "../../components/input";
import { CustomButton } from "../../components/button";
import { MessageModal } from "../../components/modal";

export const FirstLoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const { t } = useAppTranslation();
  const { authenticatedUser } = useAppSelector(
    (state: RootState) => state.session
  );
  const { password, errorMessage } = useAppSelector(
    (state: RootState) => state.user
  );
  const [state, setState] = useAppState({
    pwCheck: "",
    decider: true,
    doubleCheck: true,
    clicked: false,
  });

  const handleErrorMessageChange = useHandleAdminAlertMessage(dispatch);
  const handlePwCheckChange = useHandlAdminPwCheckChange(setState);

  const handleSave = async () => {
    await firstLogin();
    setState((prevState) => ({ ...prevState, clicked: true }));
  };

  useEffect(() => {
    setState((prevState) => ({ ...prevState, doubleCheck: true }));
    if (password !== "" && state.pwCheck !== "") {
      if (password === state.pwCheck) {
        setState((prevState) => ({ ...prevState, doubleCheck: false }));
      }
    } else {
      setState((prevState) => ({ ...prevState, doubleCheck: true }));
    }
  }, [password, state.pwCheck, setState]);

  useEffect(() => {
    if (errorMessage === "" && state.clicked) {
      setState((prevState) => ({ ...prevState, clicked: false }));
      if (
        authenticatedUser?.role.id === ROLE_ENUM.SUPER_ADMIN ||
        authenticatedUser?.role.id === ROLE_ENUM.ADMIN
      ) {
        navigate("/funds");
      }
    }
  }, [
    errorMessage,
    state.clicked,
    authenticatedUser?.role.id,
    navigate,
    setState,
  ]);

  return (
    <main className="page login-page">
      <div className="input-container">
        <InputText
          value={password}
          onChange={useHandleAdminPasswordChange(dispatch)}
          placeholder={t("first-login-password-placeholder")}
          isLogin={true}
          isPassword={true}
        />
        <InputText
          value={state.pwCheck}
          onChange={handlePwCheckChange}
          isPassword={true}
          placeholder={t("first-login-password-check-placeholder")}
          isLogin={true}
        />
      </div>

      <CustomButton
        onClick={handleSave}
        disabled={state.doubleCheck}
        title={t("first-login-save-button-title")}
      />

      {errorMessage !== "" && (
        <MessageModal
          title={t("first-login-message-modal-error-title")}
          message={errorMessage}
          onOkClick={handleErrorMessageChange}
          modalVisible={errorMessage !== null}
        />
      )}
    </main>
  );
};
