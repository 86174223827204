import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";

type ButtonProps = {
  title: string;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  navLink?: string;
};

export const CustomButton: React.FC<ButtonProps> = ({
  title,
  onClick,
  disabled,
  className,
  navLink,
}) => {
  return (
    <Button
      className={`button ${className} ${disabled ? "disabled" : ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      {navLink ? <Link to={navLink}>{title}</Link> : title}
    </Button>
  );
};
