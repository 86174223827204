import { useRef, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import {
  useAppDispatch,
  useAppSelector,
  useAppTranslation,
} from "../../constants/hooks";
import { InputText } from "../../components/input";
import {
  useHandleLogin,
  useHandleLoginAlertMessage,
  useHandleLoginEmailChange,
  useHandleLoginNavigate,
  useHandleLoginPasswordChange,
} from "../../constants/utils/login-utils";
import { RootState } from "../../store";
import { MessageModal } from "../../components/modal";
import { CustomButton } from "../../components/button";

export const LoginPage = () => {
  const dispatch = useAppDispatch();
  const { authenticatedUser } = useAppSelector(
    (state: RootState) => state.session
  );
  const { email, password, alertMessage, loginStarted, remainingTime } =
    useAppSelector((state: RootState) => state.login);
  const { t } = useAppTranslation();
  const handleAlertMessage = useHandleLoginAlertMessage(dispatch);
  const handleEmailChange = useHandleLoginEmailChange(dispatch);
  const handlePasswordChange = useHandleLoginPasswordChange(dispatch);
  const handleForgotPasswordClick = useHandleLoginNavigate();
  const handleLogin = useHandleLogin();
  const timerRef = useRef<NodeJS.Timeout>();
  const [timer, setTimer] = useState<number>(null!);

  useEffect(() => {
    if (remainingTime) {
      clearTimeout(timerRef.current);
      timerRef.current = setInterval(() => {
        const remaining = Math.floor(
          (remainingTime.getTime() - new Date().getTime()) / 1000
        );
        setTimer(remaining);
      }, 100);
    }
    if (timer === 0) {
      setTimer(null!);
      clearInterval(timerRef.current);
      handleAlertMessage();
    } else {
      return () => {
        clearInterval(timerRef.current);
      };
    }
  }, [handleAlertMessage, remainingTime, timer]);

  if (authenticatedUser) {
    const url =
      new URL(window.location.href).searchParams.get("redirect") ?? "/funds";
    return <Navigate to={url} />;
  }

  return (
    <main className="page login-page">
      <img
        className="logo"
        src="/image/otp_alapkezelo_white_logo.svg"
        alt="logo"
      />
      <div className="input-container">
        <InputText
          value={email}
          onChange={handleEmailChange}
          placeholder={t("login-email-text")}
          isLogin={true}
        />
        <InputText
          value={password}
          onChange={handlePasswordChange}
          isPassword={true}
          placeholder={t("login-password-text")}
          isLogin={true}
        />
      </div>
      <CustomButton
        onClick={handleLogin}
        disabled={loginStarted !== null}
        title={t("login-login-button-text")}
      />
      <span
        className="forgot-password-link"
        onClick={handleForgotPasswordClick}
      >
        {t("login-forgot-password")}
      </span>

      {alertMessage !== "" && (
        <MessageModal
          modalVisible={alertMessage !== ""}
          title={t("login-message-modal-error")}
          message={
            remainingTime && timer > 0 && timer !== null
              ? `${t(alertMessage)}${timer}`
              : alertMessage
          }
          onOkClick={handleAlertMessage}
        />
      )}
    </main>
  );
};
