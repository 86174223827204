import { Outlet } from "react-router-dom";

import "./login-layout.scss";

export const LoginLayout = () => {
  return (
    <main className="layout-main-page">
      <Outlet />
    </main>
  );
};
