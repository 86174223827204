import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { Message } from "../../entity";

export class GetAllMessageDTO {
  @Expose()
  @ValidateNested()
  @Type(() => Message)
  messages!: Message[];
}
