import { Form } from "antd";
import { InputText, InputTextbox } from "../../../components/input";
import { useAppSelector } from "../../../constants/hooks";
import { RootState } from "../../../store";
import { useEffect } from "react";

export const MessageDetailsForm = () => {
  const [form] = Form.useForm();
  const { email, name, phone, message } = useAppSelector(
    (state: RootState) => state.message
  );

  useEffect(() => {
    form.setFieldsValue({
      email: email,
      name: name,
      phone: phone,
      message: message,
    });
  }, [email, name, phone, message, form]);

  return (
    <Form
      form={form}
      name="message-details-form"
      labelCol={{ span: 0 }}
      wrapperCol={{ span: 24 }}
      autoComplete="off"
    >
      <Form.Item name="email">
        <InputText value={email} disabled={true} onChange={() => null} />
      </Form.Item>
      <Form.Item name="name">
        <InputText value={name} disabled={true} onChange={() => null} />
      </Form.Item>
      <Form.Item name="phone">
        <InputText value={phone} disabled={true} onChange={() => null} />
      </Form.Item>
      <Form.Item name="message">
        <InputTextbox value={message} disabled={true} />
      </Form.Item>
    </Form>
  );
};
