import { Expose } from "class-transformer";
import { IsDefined, IsNumber, IsString } from "class-validator";

export class MediaFile {
  @Expose()
  @IsDefined()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  url!: string;

  @Expose()
  @IsDefined()
  @IsString()
  filename!: string;

  @Expose()
  @IsDefined()
  @IsNumber()
  created!: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  updated!: number;
}
