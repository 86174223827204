import { forgotPassword } from "../../actions/forgot-password";
import { CustomButton } from "../../components/button";
import { InputText } from "../../components/input";
import { MessageModal } from "../../components/modal";
import {
  useAppDispatch,
  useAppSelector,
  useAppTranslation,
} from "../../constants/hooks";
import { setForgotPasswordErrorMessage } from "../../constants/reducer/forgot-password-reducer";
import { useHandleForgotPasswordEmailChange } from "../../constants/utils";
import { RootState } from "../../store";

export const ForgotPasswordPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();
  const { email, errorMessage, forgotPasswordActionStart } = useAppSelector(
    (state: RootState) => state.forgotPassword
  );

  const handleButtonClick = async () => {
    await forgotPassword();
  };

  const handleModalClose = () => {
    dispatch(setForgotPasswordErrorMessage(""));
  };

  return (
    <main className="page login-page">
      <div className="input-container">
        <InputText
          value={email}
          onChange={useHandleForgotPasswordEmailChange(dispatch)}
          placeholder={t("login-email-text")}
          isLogin={true}
        />
      </div>
      <CustomButton
        onClick={handleButtonClick}
        disabled={forgotPasswordActionStart}
        title={t("forgot-password-button-label")}
      />
      {errorMessage !== "" && (
        <MessageModal
          title=""
          message={errorMessage}
          onModalClose={handleModalClose}
          modalVisible={errorMessage !== ""}
        />
      )}
    </main>
  );
};
