import { useCallback } from "react";
import {
  setForgotPasswordEmail,
  setForgotPasswordPassword,
  setForgotPasswordPasswordCheck,
} from "../reducer/forgot-password-reducer";

export const useHandleForgotPasswordEmailChange = (dispatch: Function) => {
  const handleEmailChange = useCallback(
    (event: string) => {
      dispatch(setForgotPasswordEmail(event));
    },
    [dispatch]
  );
  return handleEmailChange;
};

export const useHandleForgotPasswordPasswordChange = (dispatch: Function) => {
  const handlePasswordChange = useCallback(
    (value: string) => {
      dispatch(setForgotPasswordPassword(value));
    },
    [dispatch]
  );
  return handlePasswordChange;
};

export const useHandleForgotPasswordPasswordCheckChange = (
  dispatch: Function
) => {
  const handlePasswordCheckChange = useCallback(
    (value: string) => {
      dispatch(setForgotPasswordPasswordCheck(value));
    },
    [dispatch]
  );
  return handlePasswordCheckChange;
};
