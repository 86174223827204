import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React from "react";

import "./searchbar.scss";
type SearchBarProps = {
  value: string;
  onChange: (string: string) => void;
  placeholder: string;
};

export const SearchBar: React.FC<SearchBarProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  return (
    <Input
      className="searchbar"
      maxLength={255}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      placeholder={placeholder}
      suffix={<SearchOutlined className="suffix-icon" />}
    />
  );
};
