import {
  basicErrorMessage,
  clearError,
} from "../../constants/reducer/base-reducer";
import { getMeFailure } from "../../constants/reducer/session-reducer";
import { store } from "../../store";

export const createCall = async (
  url: string,
  method: string,
  contentType: string,
  body?: any,
  queryParams?: any | undefined,
  skip?: boolean
): Promise<Response> => {
  const token = store.getState().session.token;

  if (!skip) {
    if (token === null) {
      store.dispatch(getMeFailure());
    }
  }

  const requestOptions: RequestInit = {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": contentType,
    },
    body: JSON.stringify(body),
  };

  let response: Response;

  try {
    response = await fetch(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/${url}${
        queryParams ? "?" + queryParams : ""
      }`,
      requestOptions
    );
  } catch (error) {
    return new Promise<Response>((res, reject) => {
      store.dispatch(basicErrorMessage("base-action-fetch-failed"));
    });
  }

  if (response.ok) {
    store.dispatch(clearError());
    return new Promise<Response>((res, rej) => {
      res(response);
    });
  } else {
    let errorMessage = "";
    const data = await response.json();
    switch (data.code) {
      case 1:
        errorMessage = "base-action-server-timeout-error";
        break;
      case 10:
        errorMessage = "base-action-invalid-parameters";
        break;
      case 20 || 30:
        errorMessage = "base-action-no-rights";
        break;
      case 5001:
        errorMessage = "base-action-session-expired";
        break;
      default:
        break;
    }

    if (errorMessage !== "") {
      store.dispatch(basicErrorMessage(errorMessage));
      throw Error(errorMessage);
    }
    return new Promise<Response>((res, rej) => {
      rej(data);
    });
  }
};
