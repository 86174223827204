import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { store } from "./store";
import { router } from "./router";
import { useAppDispatch } from "./constants/hooks";
import "antd/dist/reset.css";
import "./App.css";
import { getMe } from "./actions/user";

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMe);
  }, [dispatch]);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </React.StrictMode>
  );
}

export default App;
