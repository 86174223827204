import { useEffect } from "react";
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
  useAppTranslation,
} from "../../constants/hooks";
import { RootState } from "../../store";
import { loginOk } from "../../constants/reducer/login-reducer";
import { Navigate, Outlet } from "react-router";
import { ROLE_ENUM } from "../../constants/enum";
import { MessageModal } from "../../components/modal";
import { getMeFailure } from "../../constants/reducer/session-reducer";
import { clearError } from "../../constants/reducer/base-reducer";
import { Header } from "../../components/header";

import "./default-layout.scss";

export const DefaultLayout = () => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const { t } = useAppTranslation();

  const { authenticatedUser } = useAppSelector(
    (state: RootState) => state.session
  );
  const { errorMessage, errorTitle } = useAppSelector(
    (state: RootState) => state.base
  );
  useEffect(() => {
    dispatch(loginOk());
  }, [dispatch]);

  if (authenticatedUser?.firstLogin) {
    return <Navigate to={"/first-login"} />;
  }

  if (
    authenticatedUser?.role.id !== ROLE_ENUM.ADMIN &&
    authenticatedUser?.role.id !== ROLE_ENUM.SUPER_ADMIN
  ) {
    return <Navigate to={"/login"} />;
  }

  return (
    <div className={`layout layout--default`}>
      <Header />
      <Outlet />

      {errorMessage && (
        <MessageModal
          modalVisible={errorMessage !== ""}
          title={errorTitle ?? t("timeout-error-title")}
          message={errorMessage}
          errorTitle={true}
          onOkClick={() => {
            if (errorMessage === "base-action-no-rights") {
              navigate("/login");
              dispatch(getMeFailure());
            }
            dispatch(clearError());
          }}
        />
      )}
    </div>
  );
};
