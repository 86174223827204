import { ProfilePageForm } from "./components";
import "./profile-page.scss";
export const ProfilePage = () => {
  return (
    <main className="page profile-page">
      <div className="form-container">
        <ProfilePageForm />
      </div>
    </main>
  );
};
