export const cookie = (
  name: string,
  value: string | undefined = undefined
): string | null => {
  if (value !== undefined) {
    document.cookie = `${name}=${value}`;
  }

  let cookie = null;
  document.cookie.split(";").forEach((c: string) => {
    const [key, value] = c.split("=");
    if (key === name && value !== undefined) {
      cookie = value;
    }
  });

  return cookie;
};
