import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MessageState } from "../state/message-state";
import { Message } from "../entity";

const initialState: MessageState = {
  messages: [],
  searchText: "",
  messageActionStart: false,
  messageErrorMessage: "",
  name: "",
  email: "",
  phone: "",
  message: "",
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessageActionStart: (state, action: PayloadAction<boolean>) => {
      state.messageActionStart = action.payload;
    },
    setMessageErrorMessage: (state, action: PayloadAction<string>) => {
      state.messageErrorMessage = action.payload;
    },
    setMessages: (state, action: PayloadAction<{ messages: Message[] }>) => {
      state.messageActionStart = false;
      state.messages = action.payload.messages;
      state.messageErrorMessage = "";
    },
    setMessage: (state, action: PayloadAction<{ message: Message }>) => {
      state.messageActionStart = false;
      state.messageErrorMessage = "";
      state.name = action.payload.message.name;
      state.email = action.payload.message.email;
      state.phone = action.payload.message.phone;
      state.message = action.payload.message.message;
    },
    setMessageSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    resetMessage: (state) => {
      state.searchText = "";
      state.messageActionStart = false;
      state.messageErrorMessage = "";
    },
  },
});

export const {
  setMessageActionStart,
  setMessageErrorMessage,
  setMessages,
  resetMessage,
  setMessageSearchText,
  setMessage,
} = messageSlice.actions;
