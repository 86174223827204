import { Input } from "antd";
import React from "react";

const { TextArea } = Input;

type InputTextboxProps = {
  value: string;
  disabled: boolean;
};

export const InputTextbox: React.FC<InputTextboxProps> = ({
  value,
  disabled,
}) => {
  return (
    <TextArea
      className="text-area"
      value={value}
      disabled={disabled}
      autoSize={true}
    />
  );
};
