import { Expose, Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { Document } from "../../entity";

export class GetAllDocumentDTO {
  @Expose()
  @ValidateNested()
  @Type(() => Document)
  documents!: Document[];
}
