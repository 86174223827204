import { useEffect } from "react";
import { CustomModal, MessageModal } from "../../components/modal";
import { SearchBar } from "../../components/searchbar";
import { DataTable } from "../../components/table";
import {
  useAppDispatch,
  useAppSelector,
  useAppTranslation,
  useDebounce,
} from "../../constants/hooks";
import { useAppState } from "../../constants/hooks/global-hooks";
import {
  useHandleDocumentSearchChange,
  useHandleGetAllDocuments,
  useHandleDocumenteErrorMessageChange,
} from "../../constants/utils";
import { RootState } from "../../store";
import { DocumentColumns, DocumentDetailsForm } from "./components";
import "./documents.scss";
import { CustomButton } from "../../components/button";
import { creatDocument, modifyDocument } from "../../actions/document";
import {
  resetDocument,
  setDocumentErrorMessage,
} from "../../constants/reducer/document-reducer";
import { Form } from "antd";

export const DocumentsPage = () => {
  const [form] = Form.useForm();

  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const {
    documents,
    searchText,
    id,
    documentErrorMessage,
    title,
    file,
    language,
  } = useAppSelector((state: RootState) => state.document);
  const [state, setState] = useAppState({
    modalVisible: false,
    clicked: false,
  });

  const getAllDocuments = useHandleGetAllDocuments();
  const resetErrorMessage = useHandleDocumenteErrorMessageChange(dispatch);

  const handleModalVisible = () => {
    if (state.modalVisible === true) {
      dispatch(resetDocument());
      form.resetFields();
    }
    setState((prevState) => ({
      ...prevState,
      modalVisible: !state.modalVisible,
    }));
  };

  const checkFields = () => {
    let ok = true;

    if (file === null) {
      dispatch(setDocumentErrorMessage(t("documents-page-file-empty")));
      ok = false;
    }

    if (language === "") {
      dispatch(setDocumentErrorMessage(t("documents-page-language-empty")));
      ok = false;
    }

    if (title === "") {
      dispatch(setDocumentErrorMessage(t("documents-page-title-empty")));
      ok = false;
    }

    return ok;
  };

  const handleCreateDocument = async () => {
    if (!checkFields()) {
      return;
    }

    if (id === -1) {
      await creatDocument();
    } else {
      await modifyDocument();
    }
    setState((prevState) => ({
      ...prevState,
      clicked: true,
      modalVisible: false,
    }));
  };

  const debouncedUsers = useDebounce(
    getAllDocuments,
    searchText.trim() !== "" ? 500 : 0
  );

  useEffect(() => {
    debouncedUsers();
  }, [debouncedUsers]);

  return (
    <div className="page documents-page">
      <div className="title-text-h2">{t("documents-page-page-title")}</div>
      <div className="searchbar-container">
        <SearchBar
          value={searchText}
          onChange={useHandleDocumentSearchChange(dispatch)}
          placeholder={t("documents-page-searchbar-placeholder")}
        />

        <CustomButton
          title={t("documents-page-create-document-button-title")}
          onClick={() => {
            dispatch(resetDocument());
            handleModalVisible();
          }}
          className="searchbar-button"
        />
      </div>

      <DataTable
        columns={Object.values(DocumentColumns({ handleModalVisible }))}
        dataSource={documents}
      />
      <CustomModal
        modalVisible={state.modalVisible}
        onModalClose={handleModalVisible}
        onConfirm={handleCreateDocument}
        title={"documents-page-documents-details-modal-title"}
      >
        <DocumentDetailsForm form={form} />
      </CustomModal>

      <MessageModal
        modalVisible={documentErrorMessage !== ""}
        title={t("timeout-error-title")}
        message={documentErrorMessage}
        errorTitle={true}
        onOkClick={resetErrorMessage}
      />
    </div>
  );
};
