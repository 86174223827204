import { createBrowserRouter } from "react-router-dom";

import { LoginLayout } from "../layout/login";
import { LoginPage } from "../pages/login";
import { NotFoundPage } from "../pages/not-found";
import { RequireAuth } from "../components/require-auth";
import { DefaultLayout } from "../layout/default";
import { FirstLoginPage } from "../pages/first-login";
import { AdminPage } from "../pages/admin";
import {
  adminLoader,
  documentsLoader,
  forgotPasswordConfirmLoader,
  fundLoader,
  messageLoader,
} from "../constants/loader";
import { MessagePage } from "../pages/message";
import { FundPage } from "../pages/fund";
import { ProfilePage } from "../pages/profile";
import {
  ForgotPasswordConfirmPage,
  ForgotPasswordPage,
} from "../pages/forgot-password";
import { DocumentsPage } from "../pages/documents";

export const router = createBrowserRouter([
  {
    element: (
      <RequireAuth>
        <DefaultLayout />
      </RequireAuth>
    ),
    errorElement: null,
    path: "/",
    children: [
      {
        element: <AdminPage />,
        loader: adminLoader,
        path: "admin",
      },
      {
        element: <MessagePage />,
        loader: messageLoader,
        path: "messages",
      },
      {
        element: <FundPage />,
        loader: fundLoader,
        path: "funds",
      },
      {
        element: <DocumentsPage />,
        loader: documentsLoader,
        path: "documents",
      },
      {
        element: <ProfilePage />,
        path: "profile",
      },
    ],
  },
  {
    element: <LoginLayout />,
    errorElement: null,
    path: "/",
    children: [
      {
        element: <LoginPage />,
        path: `login`,
      },
      {
        element: <FirstLoginPage />,
        path: `first-login`,
      },
      {
        element: <ForgotPasswordPage />,
        path: `forgot-password`,
      },
      {
        element: <ForgotPasswordConfirmPage />,
        loader: forgotPasswordConfirmLoader,
        path: `forgot-password-confirm`,
      },
    ],
  },
  {
    element: <NotFoundPage />,
    path: "*",
  },
]);
