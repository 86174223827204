import { Navigate } from "react-router-dom";
import { RootState } from "../../store";
import { useAppSelector } from "../../constants/hooks";

interface RequireAuthProps {
  children: JSX.Element;
}

export const RequireAuth = ({ children }: RequireAuthProps) => {
  const { authenticatedUser } = useAppSelector(
    (state: RootState) => state.session
  );

  if (authenticatedUser === null || authenticatedUser === undefined) {
    if (window.location.pathname === "/") {
      return <Navigate to={"/login"} />;
    }
    return (
      <Navigate
        to={`/login?redirect=${encodeURIComponent(window.location.pathname)}`}
      />
    );
  }
  return children;
};
