import { LogoutOutlined } from "@ant-design/icons";
import { logoutAction } from "../../actions/user";
import { useAppMemo } from "../../constants/hooks";

export const LogoutIcon = () => {
  const handleClick = async () => {
    await logoutAction();
  };
  return useAppMemo(() => {
    return <LogoutOutlined className="logout-button" onClick={handleClick} />;
  }, []);
};
