import { Link } from "react-router-dom";
import { useAppSelector, useAppTranslation } from "../../constants/hooks";
import { useAppState } from "../../constants/hooks/global-hooks";
import { RootState } from "../../store";
import { CustomButton } from "../button";
import { LogoutIcon, MainLogo } from "../icon";
import "./header.scss";
import { ROLE_ENUM } from "../../constants/enum";

export const Header = () => {
  const { t, i18n } = useAppTranslation();
  const { authenticatedUser } = useAppSelector(
    (state: RootState) => state.session
  );
  const [state, setState] = useAppState({
    language: "hu",
  });

  const onChangeLanguageClick = (lng: string) => {
    setState((prevState) => ({ ...prevState, language: lng }));
    i18n.changeLanguage(lng);
  };
  return (
    <div className="header-main">
      <div className="header-content">
        <div className="logo-container">
          <MainLogo />
          <div
            className="language-selector"
            onClick={() =>
              onChangeLanguageClick("hu" === state.language ? "en" : "hu")
            }
          >
            {`${
              "hu" === state.language
                ? t("header_langugage_en_button")
                : t("header_langugage_hu_button")
            }`}
          </div>
        </div>
        <div className="menu-container">
          {authenticatedUser?.role.id === ROLE_ENUM.SUPER_ADMIN && (
            <CustomButton
              className={`menu-button ${
                !window.location.pathname.includes("admin") ? "inactive" : ""
              }`}
              title={t("header-admin-button-title")}
              navLink="/admin"
            />
          )}

          <CustomButton
            className={`menu-button ${
              !window.location.pathname.includes("funds") ? "inactive" : ""
            }`}
            title={t("header-funds-button-title")}
            navLink="/funds"
          />

          <CustomButton
            className={`menu-button ${
              !window.location.pathname.includes("messages") ? "inactive" : ""
            }`}
            title={t("header-messages-button-title")}
            navLink="/messages"
          />

          <CustomButton
            className={`menu-button ${
              !window.location.pathname.includes("documents") ? "inactive" : ""
            }`}
            title={t("header-documents-button-title")}
            navLink="/documents"
          />
        </div>
        <div className="text-container">
          <div>{`${t("header-logged-in-as-text")} `}</div>
          <div className="auth-user-name">
            <Link className="auth-user-profile-link" to={"/profile"}>
              {authenticatedUser?.name}
            </Link>
          </div>
          <LogoutIcon />
        </div>
      </div>
    </div>
  );
};
