import { useCallback } from "react";
import {
  changeEmail,
  changePassword,
  setAlertMessage,
} from "../reducer/login-reducer";
import { useAppNavigate } from "../hooks";
import { loginAction } from "../../actions/login";

export const useHandleLoginAlertMessage = (dispatch: Function) => {
  const handleAlertMessage = useCallback(() => {
    dispatch(setAlertMessage(""));
  }, [dispatch]);
  return handleAlertMessage;
};

export const useHandleLoginEmailChange = (dispatch: Function) => {
  const handleEmailChange = useCallback(
    (event: string) => {
      dispatch(changeEmail(event));
    },
    [dispatch]
  );
  return handleEmailChange;
};

export const useHandleLoginPasswordChange = (dispatch: Function) => {
  const handlePasswordChange = useCallback(
    (event: string) => {
      dispatch(changePassword(event));
    },
    [dispatch]
  );
  return handlePasswordChange;
};

export const useHandleLoginNavigate = () => {
  const navigate = useAppNavigate();
  const handleForgotPasswordClick = useCallback(() => {
    navigate("/forgot-password");
  }, [navigate]);
  return handleForgotPasswordClick;
};

export const useHandleLogin = () => {
  const handleButtonClick = useCallback(async () => {
    await loginAction();
  }, []);
  return handleButtonClick;
};
